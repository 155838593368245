import { memo, useContext, useMemo } from 'react';

import { Badge, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';

import { SynonymsIcon } from '../../../assets/svgs/Icons';
import ResultsStore from '../../../store/SearchResults';
import reviewMenuStyles from '../../SearchResults/styles/ReviewMenu.styles';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';

const Synonyms = ({ handleClose, onClick }: any) => {
  const { resultsState } = useContext(ResultsStore) as any;

  const synonymsEnabled = resultsState.entities?.category_name !== 'atc_classification';

  const buttonText = useMemo(() => {
    /**
     * Determines the text to be displayed on the button based on the search type and categories present in the CNF query.
     *
     * - If the search type is not 'advanced', the button text defaults to 'Synonyms'.
     * - If the search type is 'advanced', the CNF query is decoded to get the categories.
     *  - If there are both 'atc_classification' and other categories, the button text is set to 'Synonyms & ATC Classification'.
     *  - If there is only 'atc_classification', the button text is set to 'ATC Classification'.
     *
     * @returns {string} The text to be displayed on the button.
     */
    let text = 'Synonyms';
    if (resultsState.decryptedPayload.searchType === 'advanced') {
      // Decode the CNF query from the decrypted payload and convert it to list of json objects which gives the key and its value.
      const decodedCNF = cnfToAdvancedSearch(resultsState.decryptedPayload.query);

      // To decide what should be button text, we have to get all of its categories.
      const categories = decodedCNF.map((item: { category: string }) => item.category);

      // Check if the CNF has the categories other than ATC Classification.
      const otherThanATCs =
        categories.filter((item: string) => item !== 'atc_classification').length > 0;

      // Check if the CNF has 'atc_classification' category
      const atcCategories =
        categories.filter((item: string) => item === 'atc_classification').length > 0;

      // If we have ATC Classification with other categories, button text should be "Synonyms & ATC Classification" as out synonyms popup would show both.
      if (otherThanATCs && atcCategories) {
        text = 'Synonyms & ATC Classification';
      } else if (atcCategories) {
        // If we the category selected in advanced search is only ATC Classification, then show the button text `ATC Classification`
        text = 'ATC Classification';
      }
    }
    return text;
  }, []);

  if (!synonymsEnabled) return null;
  return (
    <Tooltip title='Filter the search results through synonyms' placement='left'>
      <MenuItem
        onClick={onClick}
        sx={{
          p: '8px 24px'
        }}>
        <ListItemIcon sx={{ minWidth: '14px !important' }}>
          <Badge
            variant='dot'
            invisible={
              !resultsState?.filters?.mesh_tree_numbers?.length &&
              !resultsState?.filters?.atc_code?.length
            }
            sx={reviewMenuStyles.badgeDot}
            color='primary'>
            <SynonymsIcon sx={{ height: '14px', width: '14px', color: 'gray.700' }} />
          </Badge>
        </ListItemIcon>

        <ListItemText
          primary={buttonText}
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            ml: '8px',
            color: 'gray.800'
          }}
        />
      </MenuItem>
    </Tooltip>
  );
};
export default memo(Synonyms);
