import React from 'react';
import { Box, Typography } from '@mui/material';
import DocumentIcon from '../../../../assets/svgs/PDCO/DocumentIcon';
import styles from '../../styles/Meeting.styles';
import { getMeetingHeaderDate } from '../../utils/common/getFilterOptions';

interface MeetingHeaderProps {
  emaMeetingType: string;
  meetingType: string;
  meetingDate: string;
  meetingYear: string;
  documentCount: number;
  onDocumentClick: () => void;
}

const MeetingHeader: React.FC<MeetingHeaderProps> = ({
  emaMeetingType,
  meetingType,
  meetingDate,
  meetingYear,
  documentCount,
  onDocumentClick
}) => {
  return (
    <Box id='header-wrapper'>
      <Box display='flex' justifyContent='space-between' sx={styles.headerWrapper}>
        <Typography sx={styles.headerText}>
          {`${emaMeetingType} ${meetingType} - ${getMeetingHeaderDate(
            emaMeetingType,
            meetingDate
          )}, ${meetingYear} meeting`}
        </Typography>
        <Box display='flex' alignItems='center'>
          <DocumentIcon sx={styles.iconSize} />
          <Box sx={styles.documentButton} onClick={onDocumentClick}>
            {documentCount > 0 && (
              <Typography sx={styles.documentButtonText}>
                View Documents({documentCount})
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MeetingHeader);
