import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  Divider,
  Tooltip
} from '@mui/material';
import { HideIcon, ViewIcon } from '../../../../assets/svgs/Icons';
import SectionDetails from '../MeetingDetails/SectionDetails';
import styles from '../../styles/Timeline.styles';
import { getDetailsText, isTopicValid } from '../../utils';

interface TimelineDetailsSummaryProps {
  topic: any;
  applicants: any;
  expandedItems: any;
  // eslint-disable-next-line no-unused-vars
  setExpandedItems: (expandedItems: any) => void;
}

const TimelineDetailsSummary = ({
  topic,
  applicants,
  expandedItems,
  setExpandedItems
}: TimelineDetailsSummaryProps) => {
  const [showBackground, setShowBackground] = useState(false);
  const [showDiscussion, setShowDiscussion] = useState(false);

  const handleShowBackgroundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowBackground(event.target.checked);
  };

  const handleShowDiscussionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDiscussion(event.target.checked);
  };

  const isSummaryAvailable =
    (topic?.summary && topic?.summary !== 'NA') || (topic?.action && topic?.action !== 'NA');

  const handleViewDetailsChange = (productNumber: string) => {
    if (expandedItems?.includes(productNumber)) {
      setExpandedItems(expandedItems?.filter((item: string) => item !== productNumber));
    } else {
      setExpandedItems([...expandedItems, productNumber]);
    }
  };

  return (
    <Accordion
      expanded={expandedItems?.includes(topic?.product_number)}
      onChange={() => isSummaryAvailable && handleViewDetailsChange(topic?.product_number)}
      sx={styles.timelineDetailsSummary.accordion}>
      <AccordionSummary
        expandIcon={
          expandedItems?.includes(topic?.product_number) ? (
            <HideIcon
              sx={{
                ...styles.timelineDetailsSummary.hideIcon,
                color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
              }}
            />
          ) : (
            <ViewIcon
              sx={{
                ...styles.timelineDetailsSummary.hideIcon,
                color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
              }}
            />
          )
        }
        sx={styles.timelineDetailsSummary.accordionSummary}>
        <Typography
          sx={{
            ...styles.timelineDetailsSummary.accordionSummaryText,
            color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
          }}>
          {getDetailsText(
            topic.summary,
            topic.action,
            topic.background,
            topic.discussion,
            Object.keys(expandedItems)?.length > 0
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.timelineDetailsSummary.accordionDetails}>
        <Box sx={styles.timelineDetailsSummary.detailsContainer}>
          <SectionDetails scope={topic?.scope} applicants={applicants} />
          <Box display='flex'>
            <Typography sx={styles.common.labelText}>Rapporteur:</Typography>
            <Tooltip title={topic?.rapporteur} placement='top'>
              <Typography sx={{ ...styles.common.valueText, pl: 1.5 }}>
                {topic?.rapporteur || 'NA'}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box display='flex' width='100%'>
          <Box display='flex' alignItems='center'>
            <Typography sx={styles.timelineDetailsSummary.switchText}>Show Background</Typography>
            <Switch
              checked={showBackground}
              onChange={handleShowBackgroundChange}
              name='Show Background'
              disabled={topic.background === 'NA' || !topic.background}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            <Typography sx={styles.timelineDetailsSummary.switchText}>Show Discussion</Typography>
            <Switch
              checked={showDiscussion}
              onChange={handleShowDiscussionChange}
              name='Show Discussion'
              disabled={topic.discussion === 'NA' || !topic.discussion}
            />
          </Box>
        </Box>
        <Divider orientation='horizontal' sx={styles.timelineDetailsSummary.divider} />
        {showBackground && (
          <Box>
            <Box sx={styles.timelineDetailsSummary.accordionCard}>
              <Typography
                sx={styles.timelineDetailsSummary.accordionCardHeader}
                textTransform='none'>
                Background:
              </Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography sx={styles.timelineDetailsSummary.cardSummaryText}>
                  {topic?.background}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {showDiscussion && (
          <Box>
            <Box sx={styles.timelineDetailsSummary.accordionCard}>
              <Typography sx={styles.timelineDetailsSummary.accordionCardHeader}>
                Discussion:
              </Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography sx={styles.timelineDetailsSummary.cardSummaryText}>
                  {topic?.discussion}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {topic?.action && topic?.action !== 'NA' && (
          <Box>
            <Box sx={styles.timelineDetailsSummary.accordionCard}>
              <Typography
                sx={styles.timelineDetailsSummary.accordionCardHeader}
                textTransform='none'>
                Action:
              </Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography sx={styles.timelineDetailsSummary.cardSummaryText}>
                  {topic.action}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {topic?.summary && topic?.summary !== 'NA' && (
          <Box>
            <Box sx={styles.timelineDetailsSummary.accordionCard}>
              <Typography
                sx={{
                  ...styles.timelineDetailsSummary.accordionCardHeader,
                  textTransform: 'none'
                }}>
                Details of recommendation(s):
              </Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              {topic?.summary?.map((summaryText: string) => (
                <Box component='li' sx={{ marginBottom: '8px' }} key={summaryText}>
                  <Typography sx={styles.timelineDetailsSummary.cardSummaryText}>
                    {summaryText}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(TimelineDetailsSummary);
