import { memo, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Badge } from '@mui/material';
import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';
import { ListViewIcon } from '../../../assets/svgs/Icons/ResultsViewType';
import buttonStyles from '../styles/Buttons.styles';
import { MenuWithTickOptions } from '../../../components/ui/Menu';
import { VIEW_AS_OPTIONS } from '../constants';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const ResultViewTypeButton = ({ handleParentMenuClose }: any) => {
  const location = useLocation();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [viewOptions, setViewOptions] = useState<any>(cloneDeep(VIEW_AS_OPTIONS));

  useEffect(() => {
    const viewType = resultsState.toggleViewType;
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: viewType === opt.id
    }));
    setViewOptions(viewOpts);
  }, [resultsState.toggleViewType]);

  const handleViewToggle = (viewType: string) => {
    const newValue = viewType === 'grid' ? 'grid' : 'list';
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: newValue === opt.id
    }));
    setViewOptions(viewOpts);
    resultsDispatch({
      type: ResultsActions.SET_LABEL_COMPARISON_SELECTION,
      value: false
    });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
    resultsDispatch({
      type: ResultsActions.SET_TOGGLE_VIEW_TYPE,
      value: newValue
    });
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('view', newValue.toString());
    window.history.replaceState(null, '', `${location.pathname}?${updatedSearchParams.toString()}`);
    handleParentMenuClose?.();
  };
  return (
    <MenuItemWithSubMenu
      MenuItemIcon={
        <Badge
          color='primary'
          variant='dot'
          invisible={resultsState.toggleViewType === 'grid'}
          sx={reviewMenuStyles.badgeDot}>
          <ListViewIcon sx={buttonStyles.buttonIcon} />
        </Badge>
      }
      MenuItemText='View by'>
      <MenuWithTickOptions
        options={viewOptions}
        onOptionSelect={(opt: any) => {
          handleViewToggle(opt?.id ?? 'grid');
        }}
        isNestedMenu
      />
    </MenuItemWithSubMenu>
  );
};

export default memo(ResultViewTypeButton);
