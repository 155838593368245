const styles = {
  roundedMenu: {
    '& .MuiPaper-root': { padding: '10px 0px !important', minWidth: '160px' },
    '& .MuiList-root': { padding: '8px 0 !important' }
  },
  listItemIcon: { minWidth: '14px !important' },
  menuItem: {
    padding: '6px 24px 6px 16px !important'
  },
  customCheckIcon: { color: 'gray.750', height: '14px', width: '14px' },
  listItemText: {
    fontSize: 12,
    color: 'gray.800',
    fontWeight: 400,
    ml: '6px'
  },
  badgeCount: {
    '& .MuiBadge-badge': {
      fontSize: '0.8rem !important',
      color: 'white !important',
      height: '16px',
      minWidth: '16px',
      fontWeight: 700,
      pl: '4px',
      pr: '4px'
    }
  },
  badgeDot: {
    '& .MuiBadge-badge': {
      height: '6px',
      minWidth: '6px',
      top: -2
    }
  },
  reviewButtonBadgeDot: {
    '& .MuiBadge-badge': {
      height: '6px',
      minWidth: '6px',
      top: 4
    }
  }
};

export default styles;
