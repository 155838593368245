import { useContext, useState, MouseEvent, useMemo, useRef } from 'react';
import { Badge, Button, Drawer } from '@mui/material';
import { ArrowDownIcon, GroupIcon, SortIconVariant } from '../../../assets/svgs/Icons';
import buttonStyles from '../styles/Buttons.styles';
import { RoundedMenu } from '../../../components/ui/Menu';
import SynonymsList from '../../../components/Synonyms';
import ATCTree from '../../../components/Synonyms/ATCTree';
import ResultsStore from '../../../store/SearchResults';
import RESULT_VIEW_TYPES from './constants';
import HideUnHideApplications from '../Buttons/HideUnHideApplications';
import Synonyms from '../../ResultsPage/components/Synonyms';
import DropDownButton from './DropDownButton';
import ResultViewTypeButton from '../Buttons/ResultViewTypeButton';
import ATCOption from '../../ResultsPage/components/ATCViewOption';
import reviewMenuStyles from '../styles/ReviewMenu.styles';

const ApplicationsReviewMenu = ({
  sortOptions,
  onSortOptionChange,
  showGroupBy,
  groupByOptions,
  onGroupByOptionChange,
  feature
}: {
  sortOptions: any;
  onSortOptionChange: Function;
  showGroupBy: boolean;
  groupByOptions?: any;
  onGroupByOptionChange?: Function;
  feature?: string;
}) => {
  const { resultsState } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [synonymsOpen, setSynonymsOpen] = useState<boolean>(false);
  const [atcViewOpen, setATCViewOpen] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const drawerContentRef = useRef<{ onClose: () => void }>({ onClose: () => {} });

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    // This will check the conditions of the component before closing it.
    drawerContentRef.current?.onClose();
  };

  const is505b2 = resultsState?.decryptedPayload?.source?.us?.includes('505b2');
  const showSynonymsOptions = !is505b2;

  const selectedSort = useMemo(() => sortOptions.find((opt: any) => opt.selected), [sortOptions]);

  const isAnyOptionSelected = useMemo(() => {
    return (
      (showGroupBy && resultsState.isGroupByFilterApplied) ||
      resultsState.synonymsList?.length > 0 ||
      resultsState.hiddenApplications.length > 0 ||
      resultsState.toggleViewType === 'list' ||
      resultsState?.filters?.mesh_tree_numbers?.length > 0 ||
      resultsState?.filters?.atc_code?.length > 0 ||
      (selectedSort?.selected && selectedSort?.id !== 'default')
    );
  }, [
    showGroupBy,
    resultsState.isGroupByFilterApplied,
    resultsState.hiddenApplications,
    selectedSort,
    resultsState.toggleViewType,
    resultsState.synonymsList,
    resultsState?.filters?.mesh_tree_numbers,
    resultsState?.filters?.atc_code?.length
  ]);

  return (
    <>
      <Button
        endIcon={<ArrowDownIcon sx={buttonStyles.buttonIcon} />}
        sx={buttonStyles.button}
        onClick={handleOpenMenu}>
        <Badge
          color='primary'
          variant='dot'
          invisible={!isAnyOptionSelected}
          sx={reviewMenuStyles.reviewButtonBadgeDot}>
          Sort & View
        </Badge>
      </Button>
      <RoundedMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        sx={reviewMenuStyles.roundedMenu}>
        {showGroupBy && (
          <DropDownButton
            label='Group by'
            type='action'
            options={groupByOptions}
            onOptionSelect={onGroupByOptionChange}
            highlightOnSelect
            tooltip='Select to group items'
            isMenuItem
            MenuIcon={GroupIcon}
            showBadge={resultsState.isGroupByFilterApplied}
            handleParentMenuClose={handleCloseMenu}
          />
        )}
        <DropDownButton
          label='Sort by'
          type='action'
          options={sortOptions}
          onOptionSelect={onSortOptionChange}
          tooltip='Select to sort items'
          isMenuItem
          MenuIcon={SortIconVariant}
          showBadge={selectedSort?.selected && selectedSort?.id !== 'default'}
          handleParentMenuClose={handleCloseMenu}
        />
        {resultsState?.viewType === RESULT_VIEW_TYPES.APPLICATION && feature !== 'comparsion' && (
          <ResultViewTypeButton handleParentMenuClose={handleCloseMenu} />
        )}
        {resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION && (
          <HideUnHideApplications handleParentMenuClose={handleCloseMenu} />
        )}
        {showSynonymsOptions && (
          <Synonyms
            handleClose={handleCloseMenu}
            onClick={() => {
              setSynonymsOpen(true);
              handleCloseMenu();
            }}
          />
        )}
        <ATCOption
          handleClose={handleCloseMenu}
          onClick={() => {
            setATCViewOpen(true);
            handleCloseMenu();
          }}
        />
      </RoundedMenu>

      <Drawer
        open={synonymsOpen || atcViewOpen}
        anchor='right'
        sx={{
          '& .MuiDrawer-paper': {
            width: '30%',
            maxWidth: '564px',
            minWidth: '380px'
          }
        }}
        onClose={handleDrawerClose}>
        {synonymsOpen && (
          <SynonymsList onClose={() => setSynonymsOpen(false)} drawerRef={drawerContentRef} />
        )}
        {atcViewOpen && (
          <ATCTree onClose={() => setATCViewOpen(false)} drawerRef={drawerContentRef} />
        )}
      </Drawer>
    </>
  );
};

ApplicationsReviewMenu.defaultProps = {
  groupByOptions: [],
  onGroupByOptionChange: () => {},
  feature: ''
};

export default ApplicationsReviewMenu;
