import { Box, Button, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import renderTreeNode from './TreeRenderer';
import useSynonyms from './useSynonyms';
import { CaretDownIcon } from '../../assets/svgs/Icons';
import Dropdown from './Dropdown';
import getAdvanceSearchName from './utils/getAdvanceSearchName';

import styles from './Drawer.style';
import useATCClassification from './useATCClassification';
import ApplyFilterAlert from '../NewFilters/ApplyFilterAlert';
import customTitleCase from '../../utils/titleCase';

const ExpandableTerm = ({ term, subTerms }: any) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box position='relative'>
      <Dropdown
        title={term}
        onExpand={() => setExpanded(exp => !exp)}
        isExpanded={expanded}
        isClicked={false}
        expandDisabled={false}
        hideCheckbox
        hoverEffect={false}
      />
      {expanded && (
        <Box position='absolute' left={15} top={46} height='calc(100% - 46px)'>
          <Divider
            orientation='vertical'
            sx={{
              borderColor: 'gray.200',
              borderWidth: 0.5
            }}
          />
        </Box>
      )}
      {expanded &&
        Object.keys(subTerms).map((key: string) => (
          <Box display='flex' p={1} pl={4} key={key}>
            <Typography fontSize={14} color='gray.800' fontWeight={500}>
              {key}
            </Typography>
            <Typography fontSize={14} color='primary.500' fontWeight={500}>
              ({subTerms[key].count})
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

const AdditionalTermsButton = ({ onClick, title }: any) => {
  return (
    <Box display='flex' alignItems='center' onClick={onClick}>
      <IconButton>
        <CaretDownIcon
          sx={{
            fontSize: 16,
            rotate: '90deg',
            color: 'primary.700'
          }}
        />
      </IconButton>
      <Typography
        fontSize={14}
        fontWeight={500}
        color='primary.700'
        sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}>
        {title}
      </Typography>
    </Box>
  );
};

const SynonymsFilter = ({ onClose, drawerRef }: any) => {
  const {
    synonymsTree,
    getSynonyms,
    updateTreeFilters,
    applyFilter,
    isSynonymsFilterModified,
    isLoading,
    isATCIncluded,
    error,
    resetFilter,
    searchTerm
  } = useSynonyms();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { updateATCFilters, selectedATCCodes, isATCFilterModified } = useATCClassification();
  const [synonyms, setSynonyms] = useState<any>({
    terms: null,
    type: '',
    parentTerm: ''
  });
  const [atcNode, setAtcNode] = useState<any>({
    atcCode: null,
    parentTerm: null,
    type: null
  });

  useEffect(() => {
    getSynonyms();
  }, []);

  const onNodeClick = (node: any) => {
    if (node.type?.includes('ATC')) {
      setSynonyms({
        terms: null,
        type: '',
        parentTerm: ''
      });
      setAtcNode({ atcCode: node.id, type: 'atc_class', parentTerm: node.name });
    } else {
      setAtcNode({
        atcCode: null,
        parentTerm: null,
        type: null
      });
      setSynonyms({ terms: node.synonyms, type: 'entry_terms', parentTerm: node.name });
    }
  };
  const handleAdditionalTermClick = (terms: any, type: string, parentTerm: string) => {
    setSynonyms({ terms, type, parentTerm });
  };
  const onNodeSelect = (node: any) => {
    if (node.type?.includes('ATC')) {
      updateATCFilters(node.id);
    } else {
      updateTreeFilters(node.mesh_tree_numbers);
    }
  };

  const handleClose = () => {
    if (isATCFilterModified || isSynonymsFilterModified) {
      setShowAlert(true);
    } else {
      onClose();
    }
  };
  // eslint-disable-next-line no-param-reassign
  drawerRef.current.onClose = handleClose;
  return (
    <Box overflow='hidden' height='100%' position='relative'>
      <Box sx={styles.drawerHeader} display='flex' justifyContent='space-between'>
        <Typography color='gray.0' variant='h4'>
          Synonyms
        </Typography>
        <Box onClick={handleClose} sx={{ cursor: 'pointer' }}>
          <Close sx={{ color: 'gray.0' }} />
        </Box>
      </Box>
      <Box sx={styles.mainInformationText}>
        <Box>
          {!isLoading && !error && (
            <Typography sx={styles.expandedDrawerSubInfoText}>
              Check the box of a MeSH Tree term {isATCIncluded ? 'or ATC Classification term' : ''}{' '}
              to filter applications, as indicated by the number of applications containing the
              term.
            </Typography>
          )}
        </Box>
        {/* "advanced_search_synonyms" */}
        {synonymsTree?.type === 'advanced_search_synonyms' ? (
          Object.keys(synonymsTree || {}).map(
            (key: string) =>
              key !== 'type' && (
                <Box key={key} borderBottom='1px solid #00000010' pt={2} pb={2} pl={2}>
                  <Typography fontSize={14}>{getAdvanceSearchName(key)}</Typography>
                  <Box>
                    {synonymsTree?.[key]?.trees?.map((n: any) =>
                      renderTreeNode(n, onNodeClick, onNodeSelect)
                    )}
                  </Box>
                  {Object.keys(synonymsTree?.[key]?.supplConceptTerms ?? {}).length > 0 && (
                    <AdditionalTermsButton
                      onClick={() =>
                        handleAdditionalTermClick(
                          synonymsTree?.[key]?.supplConceptTerms,
                          'suppl_concept_terms',
                          'Supplemental Concept Terms'
                        )
                      }
                      title='Supplementary Concept Terms'
                    />
                  )}
                  {Object.keys(synonymsTree?.[key]?.vivproSupplTerms ?? {}).length > 0 && (
                    <AdditionalTermsButton
                      onClick={() =>
                        handleAdditionalTermClick(
                          synonymsTree?.[key]?.vivproSupplTerms,
                          'vivpro_suppl_terms',
                          'Additional Terms'
                        )
                      }
                      title='Additional terms'
                    />
                  )}
                  {synonymsTree?.[key]?.isOnlySynonymsUsed && (
                    <Typography fontSize={14} pl={2} pt={2}>
                      There were no synonyms used in the search. Only the term{' '}
                      <b>{key.split(':')[1]}</b> was utilized.
                    </Typography>
                  )}
                </Box>
              )
          )
        ) : (
          <>
            <Box>
              {synonymsTree?.tree?.map((n: any) => renderTreeNode(n, onNodeClick, onNodeSelect))}
            </Box>
            {synonymsTree?.isOnlySynonymsUsed && (
              <Typography fontSize={14} pl={2} pt={2}>
                There were no synonyms used in the search. Only the term <b>{searchTerm}</b> was
                utilized.
              </Typography>
            )}
            {Object.keys(synonymsTree?.supplConceptTerms ?? {}).length > 0 && (
              <AdditionalTermsButton
                onClick={() =>
                  handleAdditionalTermClick(
                    synonymsTree?.supplConceptTerms,
                    'suppl_concept_terms',
                    'Supplemental Concept Terms'
                  )
                }
                title='Supplementary Concept Terms'
              />
            )}
            {Object.keys(synonymsTree?.vivproSupplTerms ?? {}).length > 0 && (
              <AdditionalTermsButton
                onClick={() =>
                  handleAdditionalTermClick(
                    synonymsTree?.vivproSupplTerms,
                    'vivpro_suppl_terms',
                    'Additional Terms'
                  )
                }
                title='Additional terms'
              />
            )}
          </>
        )}
      </Box>
      {isLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box sx={styles.loadingContainer}>
          <Typography fontSize={16}>{error}</Typography>
        </Box>
      )}
      <Box p={3} bgcolor='gray.200'>
        <Box display='flex' justifyContent='space-between'>
          <Button
            size='small'
            sx={styles.clearAll}
            onClick={() => {
              resetFilter();
              onClose();
            }}>
            Reset
          </Button>
          <LoadingButton
            size='small'
            loading={false}
            disabled={!isSynonymsFilterModified && !isATCFilterModified}
            sx={{
              ...styles.applyButton,
              backgroundColor:
                !isSynonymsFilterModified && !isATCFilterModified ? 'gray.500' : 'primary.600'
            }}
            onClick={() => applyFilter(selectedATCCodes)}>
            View Results
          </LoadingButton>
        </Box>
      </Box>
      {synonyms.terms && (
        <Box sx={styles.expandedDrawer}>
          <Box sx={styles.expandedDrawerTitle}>
            <Typography color='gray.0' variant='h4'>
              Entry Terms
            </Typography>
            <Box
              onClick={() =>
                setSynonyms({
                  terms: null,
                  type: '',
                  parentTerm: ''
                })
              }
              sx={{ cursor: 'pointer' }}>
              <Close sx={{ color: 'gray.0' }} />
            </Box>
          </Box>
          <Box sx={styles.expandedDrawerSubTitle}>
            Showing terms for: {customTitleCase(synonyms.parentTerm)}
          </Box>
          <Box sx={styles.subTermsContainer}>
            {synonyms.terms &&
              synonyms.type === 'entry_terms' &&
              Object.keys(synonyms.terms).map(syn => (
                <Box key={syn} display='flex' alignItems='center'>
                  <Typography>&#9679;</Typography>
                  <Typography fontSize={14} p={0.5} pl={1} color='gray.900' textOverflow='ellipsis'>
                    {syn}
                  </Typography>
                  <Typography fontSize={14} color='primary.500' p={0.5}>
                    ({synonyms.terms[syn].count})
                  </Typography>
                </Box>
              ))}
            {synonyms.terms &&
              (synonyms.type === 'suppl_concept_terms' || synonyms.type === 'vivpro_suppl_terms') &&
              Object.keys(synonyms.terms).map((syn: string) => (
                <ExpandableTerm key={syn} term={syn} subTerms={synonyms.terms[syn]} />
              ))}
          </Box>
        </Box>
      )}
      {atcNode.atcCode && (
        <Box sx={styles.expandedDrawer}>
          <Box sx={styles.expandedDrawerTitle}>
            <Typography color='gray.0' variant='h4'>
              ATC Code
            </Typography>
            <Box
              onClick={() =>
                setAtcNode({
                  atcCode: null,
                  parentTerm: null,
                  type: null
                })
              }
              sx={{ cursor: 'pointer' }}>
              <Close sx={{ color: 'gray.0' }} />
            </Box>
          </Box>
          <Box sx={styles.expandedDrawerSubTitle}>
            Showing ATC Code for: {customTitleCase(atcNode.parentTerm)}
          </Box>
          <Box sx={styles.subTermsContainer}>
            <Box display='flex'>
              <Typography>&#9679;</Typography>
              <Typography pl={1}>{atcNode.atcCode}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <ApplyFilterAlert
        showAlert={showAlert}
        applyFilters={applyFilter}
        selectedCategoryOptions={null}
        previousSelectedCategoryOptions={null}
        handleClose={() => {
          setShowAlert(false);
          onClose();
        }}
        setSelectedCategoryOptions={null}
      />
    </Box>
  );
};

export default React.memo(SynonymsFilter);
