import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../styles/CDPMain';

const InfoWindow: React.FC<any> = ({
  handleViewDocsClose,
  mappedEntityData,
  handleViewDocsClick
}: {
  handleViewDocsClose: () => void;
  mappedEntityData: { [key: string]: string };
  handleViewDocsClick: () => void;
}) => {
  return (
    <Box sx={styles.infoWindow}>
      <Typography fontSize={14} color='primary.600'>
        Details:
      </Typography>
      <CloseIcon onClick={handleViewDocsClose} sx={styles.closeBtn} />
      <Box height='100%'>
        <Box minHeight='100px' maxHeight={236} overflow='auto'>
          {Object.keys(mappedEntityData).map((key: string) => (
            <Box key={key}>
              <Typography
                fontSize={14}
                fontWeight={700}
                textTransform='capitalize'
                color='gray.800'
                component='span'>
                {key}
              </Typography>
              :{' '}
              <Typography
                fontSize={14}
                textTransform='capitalize'
                component='span'
                color='gray.800'>
                {mappedEntityData[key]}
              </Typography>
            </Box>
          ))}
        </Box>
        <Divider sx={{ pt: 2 }} />
        <Box display='flex' justifyContent='center' width='100%' pt={2}>
          <Button onClick={handleViewDocsClick} sx={styles.viewDocumentButton}>
            View Documents
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(InfoWindow);
