/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useMemo, useContext, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';

import { Chip, ListItem, Skeleton, Tooltip } from '@mui/material';

import { useHistory, useLocation } from 'react-router-dom';
import { deleteParamsOnTabChange } from '../../ResultsPage/utils/helpers';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import SearchBox5052B2 from '../../../components/ApprovalPathway/5052bSearchBox';
import Heading from '../../ResultsPage/components/Heading';
import ComparsionSearchBox from '../../Comparison/ComparsionSearchBox';
import { COMPARE_LABEL_METHODS, resultTabsList } from '../constants';
import RESULT_VIEW_TYPES from './constants';
import styles from '../styles/SearchResultHeader.styles';
import CompareLabelsButton from '../Buttons/CompareLabelsButton';
import VisualizeAndReportButton from '../Buttons/VisualizeAndReportButton';
import transformData from '../utils/dataTransform';
import getTabsToShow from '../utils/getTabsToShow';

const SearchResultHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [modalOpen, setModalOpen] = useState('');
  const [feature, setFeature] = useState<string>('');
  const [searchObject, setSearchObject] = useState<any>({});
  const [resultTabs, setResultTabs] = useState<any>([]);

  // TODO: source?.toLowerCase() === ct, key ===businessIntelligenceCT
  // TODO: analyticView, timelineCT, adverseEventsCT, immunogenicity ???
  const [selectedTab, setSelectedTab] = React.useState<string>('document');

  const handleChangeResultType = (key: string) => {
    if (resultsState?.viewType === key) return;
    setSelectedTab(key);
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('tab', key);
    deleteParamsOnTabChange(updatedSearchParams);
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    resultsDispatch({ type: ResultActions.SET_VIEW_TYPE, value: key });
    resultsDispatch({ type: ResultActions.SET_SELECTED_DOCUMENT, value: null });
  };

  const getTabs = useCallback(() => {
    if (searchObject?.source?.us) {
      return !searchObject.source.us.includes('505b2');
    }
    return true;
  }, [searchObject]);

  const isDownloadLabelSummaryOn = useMemo(() => {
    return (
      resultsState?.collatedSummarySelectedApplications?.length > 0 ||
      resultsState?.collatedSummaryApplications?.length > 0
    );
  }, [
    resultsState?.collatedSummarySelectedApplications,
    resultsState?.collatedSummaryApplications
  ]);

  const getApplicationData = (applData: any) => {
    if (applData && Object.keys(applData).length !== 0) {
      return transformData(applData);
    }
    return [];
  };

  useEffect(() => {
    const splitObject = location.pathname.split('/');
    if (splitObject) {
      const payload = splitObject[splitObject.length - 1];
      const searchPayload: any = decodeBase64ToObject(payload);
      setSearchObject(searchPayload);
      const sourceList = Object.values(searchPayload.source).flat() ?? [];
      const tabsToShow: any = getTabsToShow(sourceList, searchPayload.search_type);
      const uniqueTabs = new Set(tabsToShow);
      setResultTabs(resultTabsList.filter((item: any) => uniqueTabs.has(item.key)));
      if ('feature' in searchPayload && searchPayload.feature === 'comparsion') {
        const selectedComparisonMethod = COMPARE_LABEL_METHODS.enhancedComparison;
        resultsDispatch({
          type: ResultActions.SET_LABEL_COMPARISON_SELECTION_METHOD,
          value: {
            limit: selectedComparisonMethod.limit,
            value: selectedComparisonMethod.value
          }
        });
        resultsDispatch({
          type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
          value: true
        });
        setFeature('comparsion');
      } else if ('us' in searchPayload.source && searchPayload.source.us.includes('505b2')) {
        setFeature('505b2');
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (resultsState?.viewType) {
      const curSelectedTab = new URLSearchParams(location.search).get('tab') ?? 'document';
      if (curSelectedTab !== selectedTab) {
        setSelectedTab(curSelectedTab);
      }
    }
  }, [resultsState.viewType]);

  useEffect(() => {
    const originalTextEnabled =
      new URLSearchParams(location.search).get('original_text') === 'true';
    if (originalTextEnabled !== resultsState.originalTextEnabled) {
      resultsDispatch({
        type: ResultActions.SET_ORIGINAL_TEXT_ENABLED,
        value: originalTextEnabled
      });
    }
  }, [location.search]);

  if (resultsState.loading) {
    return <Skeleton variant='rectangular' width={210} height={118} />;
  }
  return (
    <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
      {!feature ? (
        <Stack direction='row'>
          {resultTabs.map((type: any) => {
            return (
              <ListItem
                key={type.key}
                sx={{
                  pl: 0,
                  pr: '8px',
                  pb: '12px'
                }}>
                <Tooltip title={type.tooltip}>
                  <Chip
                    onClick={() => handleChangeResultType(type.key)}
                    sx={{
                      fontSize: 13,
                      '&:hover': {
                        backgroundColor: (theme: any) =>
                          `${theme.palette.primary.backgroundLight} !important`,
                        borderColor: (theme: any) => `${theme.palette.primary.main} !important`
                      },
                      '&.MuiChip-filled': {
                        backgroundColor: (theme: any) =>
                          `${theme.palette.primary.lightVariant1} !important`,
                        color: (theme: any) => `${theme.palette.primary.darkVariant2} !important`,
                        fontWeight: 'bold'
                      },
                      '& > .MuiChip-label': {
                        padding: '8px 16px'
                      },
                      color: (theme: any) => theme.palette.primary.darkVariant1,
                      borderColor: (theme: any) => theme.palette.gray.lightVariant8,
                      cursor: resultsState?.viewType === type.key ? 'default' : 'pointer'
                    }}
                    variant={resultsState?.viewType === type.key ? 'filled' : 'outlined'}
                    label={
                      !resultsState?.viewType ? <Skeleton variant='text' width={30} /> : type.label
                    }
                  />
                </Tooltip>
              </ListItem>
            );
          })}
        </Stack>
      ) : (
        <Heading feature={feature} setModalOpen={setModalOpen} searchObject={searchObject} />
      )}
      <Stack sx={styles.iconsWrapper}>
        {feature !== 'comparsion' && <CompareLabelsButton />}
        {resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION && (
          <VisualizeAndReportButton
            disabled={!resultsState.applicationResults || isDownloadLabelSummaryOn}
            applications={getApplicationData(resultsState.applicationResults)}
          />
        )}
        {resultsState.viewType === RESULT_VIEW_TYPES.CT && (
          <VisualizeAndReportButton
            disabled={!resultsState.ctResults?.results}
            applications={resultsState.ctResults?.results}
          />
        )}
      </Stack>
      {modalOpen === '505b2' && (
        <SearchBox5052B2 modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
      {modalOpen === 'comparsion' && (
        <ComparsionSearchBox modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
    </Stack>
  );
};

export default memo(SearchResultHeader);
