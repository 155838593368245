import React, { useState, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import FlyoutToggle from './FlyoutToggle';
// styles
import styles from '../../styles/SectionFlyout.styles';
// constants
import { SECTIONS } from '../../constants';
import renderSections from './renderSections';
import calculateSectionCounts from './calculateSectionCounts';
import { EXPANDED_SECTIONS, EXPANDED_SUBSECTIONS } from './constants';

const SectionFlyout = ({
  activeSection,
  showSection,
  setShowSection,
  handleSectionClick,
  selectedMeetingSections
}: any) => {
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>(
    EXPANDED_SECTIONS
  );
  const [expandedSubsections, setExpandedSubsections] = useState<{
    [sectionKey: string]: { [subsectionKey: string]: boolean };
  }>(EXPANDED_SUBSECTIONS);

  const handleView = () => setShowSection(!showSection);

  const toggleSection = (sectionKey: string) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey]
    }));
  };

  const toggleSubsection = (sectionKey: string, subsectionKey: string) => {
    setExpandedSubsections(prevState => ({
      ...prevState,
      [sectionKey]: {
        ...prevState[sectionKey],
        [subsectionKey]: !prevState[sectionKey]?.[subsectionKey]
      }
    }));
  };

  const { sectionsKeyCount, mainSectionCount, subSectionCount }: any = useMemo(
    () => calculateSectionCounts(selectedMeetingSections),
    [selectedMeetingSections]
  );

  const getTextColor = (subsectionKeyValue: string) => {
    if (activeSection === subsectionKeyValue) return 'primary.600';
    return sectionsKeyCount[subsectionKeyValue] ? 'gray.800' : 'gray.500';
  };

  return (
    <Grid
      item
      sm={showSection ? 2.2 : 1}
      md={showSection ? 3 : 1}
      lg={showSection ? 2.6 : 1}
      id='sectionFlyout'
      sx={styles.sectionFlyoutWrapper}>
      <FlyoutToggle showSection={showSection} onToggle={handleView} />
      <Box
        paddingTop={2}
        display='flex'
        flexDirection='column'
        gap='16px'
        pl={1.5}
        borderRight='0.5px solid #E0E0E0'>
        {renderSections({
          sections: SECTIONS,
          expandedSections,
          toggleSection,
          expandedSubsections,
          toggleSubsection,
          handleSectionClick,
          activeSection,
          sectionsKeyCount,
          getTextColor,
          subSectionCount,
          mainSectionCount
        })}
      </Box>
    </Grid>
  );
};

export default React.memo(SectionFlyout);
