import { Dispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router';

import { AdvancedSearchScopeRegion, FeatureButton, SourceDropdown } from './types';

import {
  AdvisoryCommitteeIcon,
  CDPIcon,
  ComparisonIcon,
  ExpeditedPathwayIcon,
  GuidanceIcon,
  HTAIcon,
  MedicinePillIcon,
  PediatricsIcon,
  ConsultationIcon,
  PMRCIcon,
  Reg360Icon,
  OrphanDesignationsIcon
} from '../../assets/svgs/Icons';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import LettersIcon from '../../assets/svgs/Icons/LettersIcon';
import DDToolsIcon from '../../assets/svgs/Icons/DDToolsIcon';

/* Options in Category drop down */
export const SEARCH_TYPE_TEXT = 'TEXT';
export const SEARCH_TYPE_DATE = 'DATE';

export const ADVANCE_SEARCH_MAX_INPUTS = 5;
export const ALL_SECTIONS_KEY = 'label_section_search';

// For source US FDA - Drug & Biologics and EU EMA - Drug & Biologics
const BASE_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Generic Name',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'active_ingredients'
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: true,
    elasticSearchType: 'atc_classification'
  },
  {
    label: 'Product Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    clearSearchTerm: false,
    elasticSearchType: 'trade_name'
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'first_approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

const BASE_EU_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];
// For source US FDA - Devices
const DEVICES_CATEGORY_MENU_ITEMS = [
  {
    label: 'Definition/AOS',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Generic Name',
    value: 'generic-name',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'generic_name',
    clearSearchTerm: false
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  }
];

// For HMA source
const HMA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade_name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'Approval Date',
    value: 'approval_date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

const BASE_CANADA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: false
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

// For source Hpra
const HPRA_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'ATC Classification',
    value: 'atc-classification',
    autoCompleteAPIParam: 'atc_classification',
    urlRouteParam: 'atc_classification',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'atc_classification',
    clearSearchTerm: true
  },
  {
    label: 'Trade Name',
    value: 'trade_name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    elasticSearchType: ALL_SECTIONS_KEY,
    isAdvancedSearchOption: true
  },
  {
    label: 'Approval Date',
    value: 'approval_date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'approval_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

const BASE_JAPAN_CATEGORY_MENU_ITEMS = [
  {
    label: 'Indications',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Active Ingredient',
    value: 'active-ingredients',
    autoCompleteAPIParam: 'active_ingredients',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'Trade Name',
    value: 'trade-name',
    autoCompleteAPIParam: 'trade_name',
    urlRouteParam: 'product_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'trade_name',
    clearSearchTerm: false
  },
  {
    label: 'Formulation',
    value: 'formulation',
    autoCompleteAPIParam: 'formulation',
    urlRouteParam: 'formulation',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'formulation',
    clearSearchTerm: true
  },
  {
    label: 'Route',
    value: 'route',
    autoCompleteAPIParam: 'route',
    urlRouteParam: 'route',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'route',
    clearSearchTerm: true
  },
  {
    label: 'Sponsor',
    value: 'sponsor',
    autoCompleteAPIParam: 'sponsor',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'All Label Sections',
    value: ALL_SECTIONS_KEY,
    autoCompleteAPIParam: ALL_SECTIONS_KEY,
    urlRouteParam: ALL_SECTIONS_KEY,
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: ALL_SECTIONS_KEY
  },
  {
    label: 'Approval Date',
    value: 'approval-date',
    autoCompleteAPIParam: undefined, // Date field, does not have suggestion
    urlRouteParam: 'sales_date',
    searchType: SEARCH_TYPE_DATE,
    isAdvancedSearchOption: false,
    clearSearchTerm: true
  }
];

// For source CT Gov
const CT_GOV_CATEGORY_MENU_ITEMS = [
  {
    label: 'Conditions',
    value: 'ta-indication',
    autoCompleteAPIParam: 'ta_indication',
    urlRouteParam: 'indications_and_usage',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'indications_and_usage',
    clearSearchTerm: false
  },
  {
    label: 'Intervention',
    value: 'active-ingredient',
    autoCompleteAPIParam: 'active_ingredient',
    urlRouteParam: 'generic_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'active_ingredients',
    clearSearchTerm: false
  },
  {
    label: 'Sponsor',
    value: 'sponsor_name',
    autoCompleteAPIParam: 'sponsor_name',
    urlRouteParam: 'sponsor_name',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'sponsor_name',
    clearSearchTerm: true
  },
  {
    label: 'Endpoint',
    value: 'primary_endpoint',
    autoCompleteAPIParam: 'primary_endpoint',
    urlRouteParam: 'primary_endpoint',
    searchType: SEARCH_TYPE_TEXT,
    isAdvancedSearchOption: true,
    elasticSearchType: 'primary_endpoint',
    clearSearchTerm: true
  }
];

/*
Each category can have following field, 
  {
    label: <lable which appears in the dropdown>,
    value: <unique id or value which is used for internal coding logics>,
    autoCompleteAPIParam: <used in auto complete API payload>,
    urlRouteParam: <used in navigations, localstorage and in the backend api calls>,
    searchType: <used to decide search input component>,
    isAdvancedSearchOption: <If true, it will appear in the advanced search category dropdown>
  }
*/

/* Options in Source Dropdown */

export const SOURCE_MENU_ITEMS: SourceDropdown[] = [
  {
    label: 'US - FDA',
    value: 'usfda',
    tooltip: 'United States - Food & Drug Administration',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.APPLICATION],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.APPLICATION],
    module: [
      {
        label: 'Drugs & Biologics',
        tooltip: 'Approvals by Center for Drugs (CDER) and Biologics CBER',
        value: 'sba',
        sourceValue: 'us',
        urlRouteParam: 'US',
        db: 'us',
        autoCompleteAPIParam: 'fda',
        isAdvancedSearchOption: true,
        isApplicationsView: true,
        isCDP: true,
        isReg360: true,
        isCompare: true,
        categories: BASE_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: true,
        subscriptionKey: 'us',
        advanceSearchScope: 'biologics'
      },
      {
        label: 'Written Requests',
        tooltip: 'Pediatric Written Requests',
        value: 'wr',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us',
        autoCompleteAPIParam: 'wr',
        isApplicationsView: false,
        isAdvancedSearchOption: false,
        canSubscribeSource: true,
        subscriptionKey: 'fda-written-request'
      },
      {
        label: 'Adcomm',
        tooltip: 'Advisory Committees',
        value: 'adcomm',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us',
        autoCompleteAPIParam: 'adcomm',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: true,
        subscriptionKey: 'adcomm'
      },
      {
        label: 'Guideline',
        tooltip: 'FDA Guideline Documents',
        value: 'guidance',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us',
        autoCompleteAPIParam: 'guidance',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: true,
        subscriptionKey: 'guidance'
      },
      {
        label: 'Devices',
        tooltip: 'Approvals by Center for Devices and Radiological Health (CDRH)',
        value: 'devices',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us-devices',
        autoCompleteAPIParam: 'us-devices',
        isApplicationsView: true,
        isAdvancedSearchOption: true,
        isReg360: true,
        categories: DEVICES_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        isCompare: false,
        canSubscribeSource: true,
        subscriptionKey: 'us-devices',
        advanceSearchScope: 'devices'
      },
      {
        label: 'Compliance Letter',
        tooltip: 'Access Compliance Letters issued by US FDA',
        value: 'fda-letters',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us',
        autoCompleteAPIParam: 'fda-letters',
        isAdvancedSearchOption: false,
        canSubscribeSource: true,
        isApplicationsView: false,
        subscriptionKey: 'fda-letters'
      },
      {
        label: 'Drug Development Tools',
        tooltip: 'CDER & CBER Drug Development Tool Qualification Project',
        value: 'dd-tools',
        sourceValue: 'us',
        urlRouteParam: 'us',
        db: 'us',
        autoCompleteAPIParam: 'dd-tools',
        isAdvancedSearchOption: false,
        canSubscribeSource: false,
        isApplicationsView: false,
        subscriptionKey: 'dd-tools'
      }
    ]
  },
  {
    label: 'EU - EMA',
    value: 'euema',
    tooltip: 'European Union - European Medicines Agency',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.APPLICATION],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.APPLICATION],
    module: [
      {
        label: 'Drugs & Biologics',
        tooltip: 'Approvals by EMA',
        value: 'epar',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'ema',
        isAdvancedSearchOption: true,
        isApplicationsView: true,
        isReg360: true,
        isCompare: true,
        categories: BASE_EU_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: true,
        subscriptionKey: 'eu',
        advanceSearchScope: 'biologics'
      },
      {
        label: 'PIPs',
        tooltip: 'Paediatric Written Requests',
        value: 'pip',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'pip',
        isAdvancedSearchOption: false,
        isApplicationsView: false
      },
      {
        label: 'CHMP',
        tooltip: 'Committee for Medicinal Products for Human Use',
        value: 'chmp',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'chmp',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: true,
        subscriptionKey: 'chmp'
      },
      {
        label: 'Guideline',
        tooltip: 'EMA Guideline Documents',
        value: 'guidance',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'guidance',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: true,
        subscriptionKey: 'ema-guidance'
      },
      {
        label: 'Orphan Designation',
        tooltip: 'Orphan Designation',
        value: 'ema_orphan',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'ema_orphan',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: false,
        subscriptionKey: 'ema_orphan'
      },
      {
        label: 'PDCO',
        tooltip: 'Paediatric Committee',
        value: 'pdco',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'pdco',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: false,
        subscriptionKey: 'pdco'
      },
      {
        label: 'PRAC',
        tooltip: 'Pharmacovigilance Risk Assessment Committee ',
        value: 'prac',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'prac',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: false,
        subscriptionKey: 'prac'
      }
    ]
  },
  {
    label: 'EU - NCA & MR',
    value: 'eu_nmr',
    tooltip: 'EU - National Competent Authorities or Mutual Recognitions',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.APPLICATION],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.APPLICATION],
    module: [
      {
        label: 'Ireland',
        tooltip: 'Approvals by Health Products Regulatory Authority (HPRA)',
        value: 'hpra',
        sourceValue: 'ie',
        urlRouteParam: 'hpra',
        db: 'hpra',
        autoCompleteAPIParam: 'hpra',
        isAdvancedSearchOption: true,
        isApplicationsView: true,
        isReg360: true,
        isCompare: true,
        categories: HPRA_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: false,
        subscriptionKey: 'hpra',
        advanceSearchScope: 'biologics'
      },
      {
        label: 'HMA',
        tooltip: 'Approvals by Heads of Medicine',
        value: 'hma',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'hma',
        isApplicationsView: true,
        isReg360: true,
        isAdvancedSearchOption: true,
        categories: HMA_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        isCompare: false,
        canSubscribeSource: false,
        subscriptionKey: 'hma',
        advanceSearchScope: 'biologics'
      }
    ]
  },
  {
    label: 'Canada',
    tooltip: 'Health Canada',
    value: 'ca',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.APPLICATION],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.APPLICATION],
    module: [
      {
        label: 'Drugs & Biologics',
        tooltip: 'Approvals by Health Products and Food Branch (HPFB)',
        value: 'hpfb',
        sourceValue: 'ca',
        urlRouteParam: 'ca',
        db: 'ca',
        autoCompleteAPIParam: 'ca',
        isApplicationsView: true,
        isReg360: true,
        isAdvancedSearchOption: true,
        isCompare: true,
        categories: BASE_CANADA_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: true,
        subscriptionKey: 'ca',
        advanceSearchScope: 'biologics'
      },
      {
        label: 'Guideline',
        tooltip: 'HPFB Guideline Documents',
        value: 'guidance',
        sourceValue: 'ca',
        urlRouteParam: 'ca',
        db: 'ca',
        autoCompleteAPIParam: 'ca',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: true,
        subscriptionKey: 'ca-guidance'
      },
      {
        label: 'Consultation',
        tooltip: 'HPFB Consultation Documents',
        value: 'consultation',
        sourceValue: 'ca',
        urlRouteParam: 'ca',
        db: 'ca',
        autoCompleteAPIParam: 'ca',
        isAdvancedSearchOption: false,
        isApplicationsView: false,
        canSubscribeSource: false,
        subscriptionKey: 'ca-consultations'
      }
    ]
  },
  {
    label: 'Japan - PMDA',
    tooltip: 'Pharmaceuticals and Medical Devices Agency (PMDA)',
    value: 'jp',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.APPLICATION],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.APPLICATION],
    module: [
      {
        label: 'Drugs & Biologics',
        tooltip: 'Approvals by PMDA',
        value: 'pmda',
        sourceValue: 'jp',
        urlRouteParam: 'jp',
        db: 'jp',
        autoCompleteAPIParam: 'jp',
        isAdvancedSearchOption: true,
        isApplicationsView: true,
        isReg360: true,
        isCompare: true,
        categories: BASE_JAPAN_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: false,
        subscriptionKey: 'jp',
        advanceSearchScope: 'biologics'
      }
    ]
  },
  {
    label: 'Clinical Trials',
    tooltip: 'Clinical Trials',
    value: 'ct',
    resultTabs: [RESULT_VIEW_TYPES.DOCUMENT, RESULT_VIEW_TYPES.CT],
    advancedSearchResultTabs: [RESULT_VIEW_TYPES.CT],
    module: [
      {
        label: 'USA CT',
        tooltip: 'Trials on ClinicalTrial.gov by US NLM',
        value: 'usnlm',
        sourceValue: 'ct',
        urlRouteParam: 'usnlm',
        db: 'clinicalTrials',
        autoCompleteAPIParam: 'usnlm',
        isAdvancedSearchOption: true,
        isReg360: false,
        categories: CT_GOV_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: true,
        subscriptionKey: 'ct',
        advanceSearchScope: 'clinicalTrials'
      },
      {
        label: 'EU CTR',
        tooltip: 'Trials on clinicaltrialsregister.eu by EU',
        value: 'euctr',
        sourceValue: 'eu',
        urlRouteParam: 'EU',
        db: 'eu',
        autoCompleteAPIParam: 'euctr',
        isAdvancedSearchOption: true,
        isReg360: false,
        categories: CT_GOV_CATEGORY_MENU_ITEMS,
        isEntityDropDown: true,
        canSubscribeSource: false,
        subscriptionKey: 'euctr',
        advanceSearchScope: 'clinicalTrials'
      }
    ]
  }
];

const CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING_TEMP: { [key: string]: string } = {};

SOURCE_MENU_ITEMS.forEach(item => {
  item.module.forEach(moduleItem => {
    CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING_TEMP[moduleItem.value] = moduleItem.label;
  });
});
CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING_TEMP.ct = 'Clinical Trials';

export const CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING =
  CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING_TEMP;
/* Explore Features */
export const EXPLORE_FEATURES: { [key: string]: FeatureButton } = {
  regulatory360: {
    id: 'regulatory360',
    Icon: Reg360Icon,
    title: 'Regulatory 360',
    onClick: ({ setModalOpen }: { setModalOpen: Dispatch<SetStateAction<string>> }) => {
      setModalOpen?.('regulatory360');
    },
    clickType: 'modal',
    tooltip: 'Overview of product history from various angles'
  },
  cdp: {
    id: 'cdp',
    Icon: CDPIcon,
    title: 'Clinical Development<br />Plan (CDP)',
    onClick: ({ setModalOpen }: { setModalOpen: Dispatch<SetStateAction<string>> }) => {
      setModalOpen?.('cdp');
    },
    clickType: 'modal',
    tooltip: "Reconstruct product's CDP using clinical and regulatory data"
  },
  advisoryCommittee: {
    id: 'advisoryCommittee',
    Icon: AdvisoryCommitteeIcon,
    title: 'Advisory<br />Committee',
    onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
      history?.push('/adcomm?committee=ALL');
    },
    clickType: 'link',
    tooltip: 'Access records of US FDA advisory committee meetings'
  },
  guidance: {
    id: 'guidance',
    Icon: GuidanceIcon,
    title: 'Guideline',
    clickType: 'menu',
    tooltip: 'Access Guideline documents from various regulatory agencies',
    menuItems: [
      {
        id: 'us',
        title: 'FDA Guideline',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/guidanceDocuments');
        }
      },
      {
        id: 'eu',
        title: 'EMA Guideline',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/ema_guidance');
        }
      },
      {
        id: 'ca',
        title: 'CA Guideline',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/canada-guidance');
        }
      }
    ]
  },
  comparison: {
    id: 'comparison',
    Icon: ComparisonIcon,
    title: 'Label<br />Comparison',
    onClick: ({ setModalOpen }: { setModalOpen: Dispatch<SetStateAction<string>> }) => {
      setModalOpen?.('comparsion');
    },
    clickType: 'modal',
    tooltip: 'Compare labels between products'
  },
  expeditedPathway: {
    id: 'expeditedPathway',
    Icon: ExpeditedPathwayIcon,
    title: 'Expedited<br />Pathway',
    onClick: ({ setModalOpen }: { setModalOpen: Dispatch<SetStateAction<string>> }) => {
      setModalOpen?.('expeditedPathway');
    },
    clickType: 'modal',
    tooltip: 'Find products with designations like AA, BT, FT, OR'
  },
  pmr: {
    id: 'pmr',
    Icon: PMRCIcon,
    title: 'PMR/PMC',
    clickType: 'menu',
    tooltip: 'Track PMR/PMC status and labeling changes post completion',
    menuItems: [
      {
        id: 'surveillance',
        title: 'Surveillance',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/pmr/surveillance');
        }
      },
      {
        id: 'impact',
        title: 'Impact',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/pmr/impact');
        }
      }
    ]
  },
  '505b2': {
    id: '505b2',
    title: '505 (B)(2)',
    onClick: ({ setModalOpen }: { setModalOpen: Dispatch<SetStateAction<string>> }) => {
      setModalOpen?.('505b2');
    },
    Icon: MedicinePillIcon,
    tooltip: 'Find 505b2-approved products by formulation or route',
    clickType: 'modal'
  },
  pediatrics: {
    id: 'pediatrics',
    Icon: PediatricsIcon,
    title: 'Pediatrics',
    clickType: 'menu',
    tooltip:
      'Access pediatric Written Requests (WR) for FDA and Investigation Plans (PIPs) for EMA',
    menuItems: [
      {
        id: 'fda-wrs',
        title: 'FDA WRs',
        tooltip: 'FDA Pediatric Written Request',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/pediatrics');
        }
      },
      {
        id: 'ema-pips',
        title: 'EMA PIPs',
        tooltip: 'EMA Paediatric Investigation Plan',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/pediatricsEMA');
        }
      },
      {
        id: 'euctr-pediatrics',
        title: 'EU CTR',
        tooltip: 'EU Clinical Trial Register',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/euctr/pediatrics');
        }
      }
    ]
  },
  consultation: {
    id: 'consultation',
    Icon: ConsultationIcon,
    title: 'Public<br />Consultation',
    clickType: 'menu',
    tooltip: 'Access Consultation documents from various regulatory agencies',
    menuItems: [
      {
        id: 'canada-consultation',
        title: 'CA Consultation',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/consultation/canada');
        }
      }
    ]
  },
  hta: {
    id: 'hta',
    Icon: HTAIcon,
    title: 'HTA',
    tooltip: 'Access all National Institute for Health and Care Excellence (NICE) documents',
    onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
      history?.push('/htaDocuments');
      sessionStorage.removeItem('aria_cache');
    },
    clickType: 'link'
  },
  'fda-letters': {
    id: 'fda-letters',
    Icon: LettersIcon,
    title: 'Compliance<br />Letter',
    onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
      history?.push('/letters');
    },
    clickType: 'link',
    tooltip: 'Warnings and Untitled Letters'
  },
  'dd-tools': {
    id: 'dd-tools',
    Icon: DDToolsIcon,
    title: 'Drug Development<br />Tool (DDT)',
    onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
      history?.push('/drug-development-tools');
    },
    clickType: 'link',
    tooltip: 'Access Drug Development Tools'
  },
  ema_orphan: {
    id: 'ema_orphan',
    Icon: OrphanDesignationsIcon,
    title: 'Orphan<br />Designation',
    onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
      history?.push('/orphan-designations');
    },
    clickType: 'link',
    tooltip: 'Opinions on Orphan Medicinal Product Designation'
  },
  ema_meetings: {
    id: 'ema_meetings',
    Icon: AdvisoryCommitteeIcon,
    title: 'EMA Meetings',
    menuItems: [
      {
        id: 'chmp',
        title: 'CHMP',
        tooltip: 'Committee for Medicinal Products for Human Use',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/chmp');
        }
      },
      {
        id: 'pdco',
        title: 'PDCO',
        tooltip: 'Paediatric Committee',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/pdco');
        }
      },
      {
        id: 'prac',
        title: 'PRAC',
        tooltip: 'Pharmacovigilance Risk Assessment Committee ',
        onClick: ({ history }: { history: RouteComponentProps['history'] }) => {
          history?.push('/prac');
        }
      }
    ],
    clickType: 'menu',
    tooltip: 'Access records of Committee for EMA meetings.'
  }
};

export const SOURCE_DROPDOWN_TYPES = {
  NAV: 'nav',
  APPLICATIONS_VIEW: 'applicationsView',
  CT_VIEW: 'ctView',
  DOCUMENTS_VIEW: 'documentsView',
  DEFAULT: 'default'
} as const;

export const bottomDrawerHeight = 170;
export const bottomDrawerHeightExpanded = 250;

export const tipsForBetterSearchLink =
  'https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/Searching_with_Booleans_Reference_Sheet.pdf';

export const SURVEY_QUESTION_TYPE = {
  BUTTON: 'button',
  EMOJI_BUTTON: 'emojiButton',
  RADIO: 'radio',
  INPUT: 'input'
};

const RIA_CUSTOMER_EXPERIENCE_FEEDBACK = {
  question_1: {
    title: 'Question 1',
    question: 'How often do you use RIA?',
    type: SURVEY_QUESTION_TYPE.BUTTON,
    options: {
      a: 'Daily',
      b: 'Weekly',
      c: 'Monthly',
      d: 'Quarterly',
      e: 'Rarely'
    },
    isMandatory: true
  },
  question_2: {
    title: 'Question 2',
    question: 'We recently upgraded our platform, how satisfied are you with our new version?',
    type: SURVEY_QUESTION_TYPE.EMOJI_BUTTON,
    options: {
      a: 'Very Dissatisfied',
      b: 'Slightly Dissatisfied',
      c: 'Neutral',
      d: 'Moderately Satisfied',
      e: 'Very Satisfied'
    },
    emojis: {
      a: 'pensiveFace',
      b: 'unamusedFace',
      c: 'neutralFace',
      d: 'smilingFace',
      e: 'grinningFace'
    },
    isMandatory: true
  },
  question_3: {
    title: 'Question 3',
    question:
      'How would you rate the ease of accomplishing tasks in the new version compared to the previous version?',
    type: SURVEY_QUESTION_TYPE.RADIO,
    options: {
      a: 'Much Worse',
      b: 'Worse',
      c: 'No Change',
      d: 'Better',
      e: 'Much Better'
    },
    isMandatory: true
  },
  question_4: {
    title: 'Question 4',
    question: "Have any comments or suggestions on your mind? We'd love to hear!",
    type: SURVEY_QUESTION_TYPE.INPUT,
    isMandatory: false
  }
};

export const SURVEYS = {
  riaCustomerExperienceFeedback: {
    title: 'RIA CUSTOMER EXPERIENCE FEEDBACK',
    questions: RIA_CUSTOMER_EXPERIENCE_FEEDBACK
  }
};

export const ADVANCED_SEARCH_SCOPE_LIST: AdvancedSearchScopeRegion[] = [
  {
    label: 'Drugs & Biologics',
    value: 'biologics',
    enabled: []
  },
  {
    label: 'Devices',
    value: 'devices',
    enabled: ['us']
  },
  {
    label: 'Clinical Trials',
    value: 'clinicalTrials',
    enabled: ['us', 'eu']
  }
];

export const ADVANCED_SEARCH_REGION_LIST: AdvancedSearchScopeRegion[] = [
  {
    label: 'US - FDA',
    value: 'us',
    enabled: [],
    module: {
      biologics: 'sba',
      devices: 'devices',
      clinicalTrials: 'usnlm'
    }
  },
  {
    label: 'EU - EMA',
    value: 'eu',
    enabled: ['biologics', 'clinicalTrials'],
    module: {
      biologics: 'epar',
      clinicalTrials: 'euctr'
    }
  },
  {
    label: 'EU - HMA',
    value: 'hma',
    enabled: ['biologics'],
    module: {
      biologics: 'hma'
    }
  },
  {
    label: 'EU - HPRA',
    value: 'ie',
    enabled: ['biologics'],
    module: {
      biologics: 'hpra'
    }
  },
  {
    label: 'CA',
    value: 'ca',
    enabled: ['biologics'],
    module: {
      biologics: 'hpfb'
    }
  },
  {
    label: 'JP',
    value: 'jp',
    enabled: ['biologics'],
    module: {
      biologics: 'pmda'
    }
  }
];

export const NESTED_LABEL_MAPPING = [
  {
    parent: 'indication and usage',
    child: ['main indications and usage', 'limitations of use', 'indications and usage (primary)']
  },
  {
    parent: 'warnings and precautions',
    child: ['warnings', 'important precautions', 'precautions concerning use', 'other precautions']
  },
  {
    parent: 'clinical pharmacology',
    child: ['pharmacokinetics', 'pharmacology']
  },
  {
    parent: 'how supplied/storage and handling',
    child: ['packaging', 'precautions for handling']
  }
];

export const LABEL_MAPPING_OPTION = [
  {
    id: 'common_mapping',
    label: 'RIA common'
  },
  {
    id: 'region_based_mapping',
    label: 'Region Based'
  }
];

export const ENABLE_CLINICAL_TRIALS = ['us', 'eu'];

export const SOURCE_ORDER = ['us', 'eu', 'hma', 'hpra', 'ca', 'jp'];
