import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { memo, useContext } from 'react';

import Badge from '@mui/material/Badge';

import { SynonymsIcon } from '../../../assets/svgs/Icons';
import ResultsStore from '../../../store/SearchResults';

import reviewMenuStyles from '../../SearchResults/styles/ReviewMenu.styles';

const ATCOption = ({ handleClose, onClick }: any) => {
  const { resultsState } = useContext(ResultsStore) as any;

  const { category_name: categoryName } = resultsState.entities;
  const atcEnabled = categoryName === 'atc_classification';

  if (!atcEnabled) return null;

  return (
    <Tooltip title='Filter the search results through ATC Classification' placement='left'>
      <MenuItem
        onClick={onClick}
        sx={{
          p: '8px 24px'
        }}>
        <ListItemIcon sx={{ minWidth: '14px !important' }}>
          <Badge
            variant='dot'
            invisible={!resultsState?.filters?.atc_code?.length}
            sx={reviewMenuStyles.badgeDot}
            color='primary'>
            <SynonymsIcon sx={{ height: '14px', width: '14px', color: 'gray.750' }} />
          </Badge>
        </ListItemIcon>
        <ListItemText
          primary='ATC Classification'
          sx={{
            fontSize: '12px',
            fontWeight: '400',
            ml: '8px',
            color: 'primary.darkVariant1'
          }}
        />
      </MenuItem>
    </Tooltip>
  );
};
export default memo(ATCOption);
