import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider
} from '@mui/material';
import { HideIcon, ViewIcon } from '../../../../assets/svgs/Icons';
import styles from '../../../EMAMeetings/styles/Meeting.styles';
import ModifiedProductNumber from './ModifiedProductNumber';
import { getDetailsText, isTopicValid } from '../../utils';
import CustomSwitch from '../../../EMAMeetings/components/common/CustomSwitch';

interface SectionAccordionProps {
  topic: any;
  details: any;
  expanded: string | false;
  // eslint-disable-next-line no-unused-vars
  handleChange: (id: string) => void;
  subSectionNumber: any;
  idx: any;
}

const SectionAccordion: React.FC<SectionAccordionProps> = ({
  topic,
  details,
  expanded,
  handleChange,
  subSectionNumber,
  idx
}) => {
  const [showBackground, setShowBackground] = useState(false);
  const [showDiscussion, setShowDiscussion] = useState(false);

  const handleShowBackgroundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowBackground(event.target.checked);
  };

  const handleShowDiscussionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDiscussion(event.target.checked);
  };

  return (
    <Accordion
      expanded={expanded === `${subSectionNumber}-${idx}`}
      onChange={() => handleChange(`${subSectionNumber}-${idx}`)}
      defaultExpanded
      sx={{ ...styles.cardSummaryAccordion, '&.Mui-expanded': { margin: 0 } }}>
      <AccordionSummary
        expandIcon={
          expanded === `${subSectionNumber}-${idx}` ? (
            <HideIcon
              sx={{
                ...styles.hideIcon,
                color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
              }}
            />
          ) : (
            <ViewIcon
              sx={{
                ...styles.hideIcon,
                color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
              }}
            />
          )
        }
        aria-controls={`${subSectionNumber}-${idx}`}
        id={`${subSectionNumber}-${idx}`}
        sx={{ ...styles.cardSummaryAccordionSummary, pt: 0.75 }}>
        <Typography
          fontWeight={500}
          sx={{
            ...styles.cardAccordionHideSummaryText,
            color: isTopicValid(topic) ? 'primary.700' : 'gray.500'
          }}>
          {getDetailsText(
            topic.summary,
            topic.action,
            topic.background,
            topic.discussion,
            expanded === `${subSectionNumber}-${idx}`
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.cardSummaryAccordionDetails}>
        <Box display='flex' width='100%' sx={{ py: 1.5, gap: 3 }}>
          <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
            <Typography sx={styles.meetingSummarySwtich}>Show Background</Typography>
            <CustomSwitch
              checked={showBackground}
              onChange={handleShowBackgroundChange}
              name='Show Background'
              disabled={topic.background === 'NA'}
            />
          </Box>
          <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
            <Typography sx={styles.meetingSummarySwtich}>Show Discussion</Typography>
            <CustomSwitch
              checked={showDiscussion}
              onChange={handleShowDiscussionChange}
              name='Show Discussion'
              disabled={topic.discussion === 'NA'}
            />
          </Box>
        </Box>
        <Divider orientation='horizontal' sx={{ borderColor: 'gray.200', mb: 1.5 }} />

        {showBackground && (
          <Box sx={styles.accordionCardWrapper}>
            <Box sx={styles.accordionCard}>
              <Typography sx={styles.accordionCardHeader} textTransform='none'>
                Background:
              </Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography
                  sx={styles.accordionSummaryText}
                  dangerouslySetInnerHTML={{ __html: topic.background }}
                />
              </Box>
            </Box>
          </Box>
        )}

        {showDiscussion && (
          <Box sx={styles.accordionCardWrapper}>
            <Box sx={styles.accordionCard}>
              <Typography sx={styles.accordionCardHeader}>Discussion:</Typography>
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography
                  sx={styles.accordionSummaryText}
                  dangerouslySetInnerHTML={{ __html: topic.discussion }}
                />
              </Box>
            </Box>
          </Box>
        )}
        {topic.action && topic.action !== 'NA' && (
          <Box sx={styles.accordionCardWrapper}>
            <Box sx={styles.accordionCard}>
              <Typography sx={styles.accordionCardHeader} textTransform='none'>
                Action:
              </Typography>
              <ModifiedProductNumber data={details?.applicants} />
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              <Box component='li' sx={{ marginBottom: '8px' }}>
                <Typography
                  sx={styles.accordionSummaryText}
                  dangerouslySetInnerHTML={{ __html: topic.action }}
                />
              </Box>
            </Box>
          </Box>
        )}
        {topic.summary && topic.summary !== 'NA' && (
          <Box sx={styles.accordionCardWrapper}>
            <Box sx={styles.accordionCard}>
              <Typography sx={{ ...styles.accordionCardHeader, textTransform: 'none' }}>
                Details of recommendation(s):
              </Typography>
              {!topic.action ||
                (topic.action === 'NA' && <ModifiedProductNumber data={details?.applicants} />)}
            </Box>
            <Box component='ul' sx={{ margin: 0, paddingLeft: 3 }}>
              {topic.summary.map((summaryText: any) => (
                <Box component='li' sx={{ marginBottom: '8px' }} key={summaryText}>
                  <Typography
                    sx={styles.accordionSummaryText}
                    dangerouslySetInnerHTML={{ __html: summaryText }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(SectionAccordion);
