const getFiltersOptions = (meetingFilters: any, apiData: any) => {
  const filters = meetingFilters.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};
const abbreviateMonths = (input: string): string => {
  return input.replace(
    /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/g,
    match => match.substring(0, 3)
  );
};
export const getMeetingHeaderDate = (committee: string, meetingDate: any) => {
  if (committee === 'PRAC') {
    return abbreviateMonths(meetingDate);
  }

  return `${meetingDate.split(' ')[1].splice(3)} ${meetingDate.split(' ')[0].splice(3)}`;
};

export default getFiltersOptions;
