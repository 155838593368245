import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import DropDownButton from './DropDownButton';
import styles from '../styles/ApplicationCard.styles';
import SourceDropDown from '../../Home/components/SourceDropDown';
import Stats from './Stats';
import { getApplicationsSourceDropdown } from '../../Home/utils';
import { SOURCE_DROPDOWN_TYPES } from '../../Home/const';
import AdvancedSearch from '../../Home/components/AdvancedSearch';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import RESULT_VIEW_TYPES from './constants';
import ShareButton from '../Buttons/ShareButton';
import ApplicationsDownloadButton from '../Buttons/ApplicationsDownloadButton';
import ApplicationsReviewMenu from './ApplicationsReviewMenu';
import LanguageToggleButton from '../../../components/LanguageToggleComponents/LanguageToggleButton';

const ApplicationsHeader = ({
  sortOptions,
  onSortOptionChange,
  groupByOptions,
  onGroupByOptionChange,
  showGroupBy,
  entityCategory,
  entityValue,
  entityCategoryOptions,
  entityTextOptions,
  applyCategoryEntity,
  applyEntityText,
  selectedSources,
  handleSourceChange,
  makeSearch,
  searchType,
  stats,
  feature,
  isLabelComparisionSelection,
  isDownloadLabelSummaryOn,
  onResize,
  totalCategory
}: any) => {
  const location = useLocation();
  const history = useHistory();
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);

  const applicationSourceDropdown = useMemo(
    () => getApplicationsSourceDropdown(isLabelComparisionSelection),
    [isLabelComparisionSelection]
  );

  const favoriteInvokedBy = useMemo(() => {
    if (resultsState.decryptedPayload?.searchType === 'advanced') {
      return 'advancedSearch';
    }
    if (
      resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION ||
      resultsState.viewType === RESULT_VIEW_TYPES.CT
    ) {
      return 'applicationSearch';
    }
    if (resultsState.viewType === RESULT_VIEW_TYPES.DOCUMENT) {
      return 'documentSearch';
    }

    return 'search';
  }, [resultsState.decryptedPayload, resultsState.viewType]);

  const getSource = useCallback(() => {
    if (resultsState && Object.keys(resultsState.applicationResults).length !== 0) {
      return Object.keys(resultsState.applicationResults).map((item: string) =>
        item === 'ie' ? 'hpra' : item
      );
    }
    return '';
  }, [resultsState.applicationResults]);

  const toggleTextLanugage = (value: boolean) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('original_text', value.toString());
    history.replace(`${location.pathname}?${updatedSearchParams.toString()}`);
    resultsDispatch({ type: ResultActions.SET_ORIGINAL_TEXT_ENABLED, value });
  };
  useEffect(() => {
    onResize?.();
  }, []);

  return (
    <Box display='flex' width='100%'>
      {searchType === 'advanced' && feature !== '505b2' && (
        <Stack direction='row' justifySelf='start' alignItems='center'>
          <Typography variant='body1' sx={{ fontWeight: 700, fontSize: 14, color: 'gray.800' }}>
            Showing results for
          </Typography>
          <AdvancedSearch selectedSources={selectedSources} type='text' />
        </Stack>
      )}
      {searchType !== 'advanced' && (
        <Box
          justifySelf='start'
          display='flex'
          alignItems='center'
          sx={{ ...(isDownloadLabelSummaryOn && styles.disabledResultsFor) }}>
          <Box fontSize={14} fontWeight={700}>
            Showing results for
          </Box>
          <Stack direction='row' alignItems='center'>
            <SourceDropDown
              selectedSources={selectedSources}
              handleChange={handleSourceChange}
              options={applicationSourceDropdown}
              anchorEl={sourceDropDownAnchorEl}
              setAnchorEl={setSourceDropDownAnchorEl}
              type={SOURCE_DROPDOWN_TYPES.APPLICATIONS_VIEW}
              miniminumOneSelected
              showApplyButton
              makeSearch={makeSearch}
            />
          </Stack>
          <Divider orientation='vertical' sx={styles.divider} />
          <DropDownButton
            label={
              entityCategory ||
              `${resultsState?.entities?.category_label} (${totalCategory})` ||
              'Category'
            }
            options={entityCategoryOptions}
            onOptionSelect={applyCategoryEntity}
            tooltip='Switch category'
          />

          <Divider orientation='vertical' sx={styles.divider} />
          <DropDownButton
            label={
              _.startCase(entityValue)?.replace(/_/g, '') ||
              _.startCase(resultsState?.entities?.search_term)?.replace(/_/g, '') ||
              'Entity Text'
            }
            options={entityTextOptions}
            onOptionSelect={applyEntityText}
            tooltip='Switch query'
          />
        </Box>
      )}

      {['505b2'].includes(feature) ? (
        <Box sx={{ m: 'auto 0' }}>
          <Stats stats={stats} />
        </Box>
      ) : null}
      <Box display='flex' ml='auto' alignItems='center'>
        <ApplicationsReviewMenu
          showGroupBy={showGroupBy}
          groupByOptions={groupByOptions}
          onGroupByOptionChange={onGroupByOptionChange}
          sortOptions={sortOptions}
          onSortOptionChange={onSortOptionChange}
          feature={feature}
        />
        <Divider orientation='vertical' sx={styles.verticalDivider} />
        <LanguageToggleButton
          source={getSource()}
          originalTextEnabled={resultsState.originalTextEnabled}
          toggleTextLanugage={toggleTextLanugage}
          color='green'
        />
        <SubscriptionActions
          isButton
          isSubscribed={resultsState.isSubscribed}
          invokedBy={searchType === 'advanced' ? 'advanced_search' : 'quick_search'}
          id={resultsState?.searchIds?.applicationSearchId}
          setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) => {
            resultsDispatch({ type: ResultActions.SET_IS_SUBSCRIBED, value: isSubscribed });
          }}
        />
        <FavoriteAndProjectActions
          invokedBy={favoriteInvokedBy}
          source=''
          id={resultsState?.searchIds?.applicationSearchId}
          isButton
          isFavorite={resultsState?.isFavorite ?? false}
          inProjects={resultsState?.inProjects ?? []}
          setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
            resultsDispatch({ type: ResultActions.SET_IS_FAVORITE, value: isFavorite });
          }}
          setInProjectInHook={({ project }: { project: any }) => {
            let projects = [];
            if (project?.inProject) {
              projects = resultsState?.inProjects.filter(
                (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
              );
            } else {
              projects = [
                ...(resultsState?.inProjects ?? []),
                {
                  id: project?.project_id ?? project?.id,
                  name: project?.name
                }
              ];
            }
            resultsDispatch({ type: ResultActions.SET_IN_PROJECTS, value: projects });
          }}
        />
        <ApplicationsDownloadButton />
        <ShareButton
          searchType={resultsState.decryptedPayload?.searchType ?? ''}
          search={resultsState.decryptedPayload?.query}
        />
      </Box>
    </Box>
  );
};

export default React.memo(ApplicationsHeader);
