const MODULES_PATHNAME_STYLE: any = [
  { path: '/adcomm', style: { backgroundColor: '#f8f8f8' } },
  { path: '/guidanceDocuments', style: { backgroundColor: '#f8f8f8' } },
  { path: '/ema_guidance', style: { backgroundColor: '#f8f8f8' } },
  { path: '/canada-guidance', style: { backgroundColor: '#f8f8f8' } },
  { path: '/designation', style: { backgroundColor: '#f8f8f8' } },
  { path: '/pmr/surveillance', style: { backgroundColor: '#f8f8f8' } },
  { path: '/pmr/impact', style: { backgroundColor: '#f8f8f8' } },
  { path: '/pediatrics', style: { backgroundColor: '#f8f8f8' } },
  { path: '/pediatricsEMA', style: { backgroundColor: '#f8f8f8' } },
  { path: '/htaDocuments', style: { backgroundColor: '#f8f8f8' } },
  { path: '/chmp', style: { backgroundColor: '#f8f8f8' } },
  { path: '/letters', style: { backgroundColor: '#f8f8f8' } },
  { path: '/drug-development-tools', style: { backgroundColor: '#f8f8f8' } },
  { path: '/notifications', style: { backgroundColor: '#f8f8f8' } },
  { path: '/pdco', style: { backgroundColor: '#f5f5f5', p: 0, height: `calc(100vh - 74px)` } },
  { path: '/prac', style: { backgroundColor: '#f5f5f5', p: 0, height: `calc(100vh - 74px)` } }
];

export default MODULES_PATHNAME_STYLE;
