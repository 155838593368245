import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// styles
import { Divider } from '@material-ui/core';
import styles from '../../styles/SectionFlyout.styles';
import { renderTopics } from './renderTopics';

const Subsection = ({
  subsectionKey,
  topics,
  expandedSubsections,
  toggleSubsection,
  handleSectionClick,
  activeSection,
  sectionsKeyCount,
  getTextColor,
  sectionKey,
  subSectionCount
}: any) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={() => toggleSubsection(sectionKey, subsectionKey)}>
      <IconButton size='small' sx={{ padding: 0 }}>
        {expandedSubsections[sectionKey]?.[subsectionKey] ? (
          <ArrowDropUpIcon sx={{ color: 'gray.300' }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: 'gray.300' }} />
        )}
      </IconButton>
      <Typography sx={styles.subSectionKeyText}>
        {`${subsectionKey} (${subSectionCount[sectionKey][subsectionKey]})`}
      </Typography>
    </Box>
    {expandedSubsections[sectionKey]?.[subsectionKey] && (
      <Box display='flex'>
        <Divider orientation='vertical' style={styles.verticalDivider} />
        {renderTopics(topics, handleSectionClick, activeSection, sectionsKeyCount, getTextColor)}
      </Box>
    )}
  </Box>
);

const renderSubsections = ({
  subsections,
  expandedSubsections,
  toggleSubsection,
  handleSectionClick,
  activeSection,
  sectionsKeyCount,
  getTextColor,
  sectionKey,
  subSectionCount
}: any) => {
  return Object.entries(subsections)
    .filter(([, topics]: any) => topics.length > 0)
    .map(([subsectionKey, topics]: any) => (
      <Subsection
        key={subsectionKey}
        subsectionKey={subsectionKey}
        topics={topics}
        expandedSubsections={expandedSubsections}
        toggleSubsection={toggleSubsection}
        handleSectionClick={handleSectionClick}
        activeSection={activeSection}
        sectionsKeyCount={sectionsKeyCount}
        getTextColor={getTextColor}
        sectionKey={sectionKey}
        subSectionCount={subSectionCount}
      />
    ));
};

export default renderSubsections;
