import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  memo,
  useEffect,
  MouseEvent
} from 'react';
import {
  Stack,
  Box,
  Divider,
  IconButton,
  Typography,
  Drawer,
  Button,
  TextField,
  InputAdornment,
  Badge,
  Tooltip
} from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { startCase, debounce, cloneDeep, difference, intersectionBy, flatMap } from 'lodash';

import VirtualizedGrid from '../../SearchResults/components/CardsVirtualScroll';
import ExpandableApplicationGroup from '../../SearchResults/components/ExpandableApplicationGroup';

import ResultActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';

import VivproDatagrid from '../../../components/Datagrid';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';

import { getGenericSearch, getPdfResource } from '../../../api/pages/ResultsPage';
import ComparisonDialogComp, {
  ModeOfComparisonDialog
} from '../../../components/Comparison/ComparisonDialog';
import SourceDropDown from '../../Home/components/SourceDropDown';
import useEntities from '../../SearchResults/hooks/useEntities';
import { SOURCE_DROPDOWN_TYPES } from '../../Home/const';
import { getApplicationsSourceDropdown, createSourceModulePayloadMapping } from '../../Home/utils';
import DropDownButton from '../../SearchResults/components/DropDownButton';
import {
  MagnifyingGlassIcon,
  ArrowDownIcon,
  SortIconVariant,
  GroupIcon
} from '../../../assets/svgs/Icons';
import {
  apiDataToCardProps,
  VIEW_AS_OPTIONS,
  COMPARE_LABEL_TYPES,
  GROUP_BY_OPTIONS,
  SORT_OPTIONS
} from '../../SearchResults/constants';
import entityHandler from '../../SearchResults/utils/entityHandler';
import useApplications from '../../SearchResults/hooks/useApplications';
import { RoundedMenu, MenuWithTickOptions } from '../../../components/ui/Menu';
import MenuItemWithSubMenu from '../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import { ListViewIcon } from '../../../assets/svgs/Icons/ResultsViewType';
import PDFComparisonDialog from '../../../components/Comparison/PDFComparisonDialog';
import selectionViewStyles from '../styles/SelectionView.styles';
import { DATAGRID_COLUMN_MAPPING } from '../utils/constants';
import removeQuotes from '../../SearchResults/utils/removeQuotes';
import { sortAndGroupApplications } from '../../SearchResults/utils/sortAndGroup';
import Loading from '../../SearchResults/components/Loading';
import EmptyResult from '../../SearchResults/components/EmptyResult';

const mode: ModeOfComparisonDialog = {
  child_labelList_dependent_on_parent: false,
  section_binding: false,
  allsections: true,
  default_section_all_section: true,
  sortOnDate: false
};

const SelectionView = ({
  open,
  onClose,
  sources,
  comparisonApplications,
  showSecondaryNavigation = false,
  isExternalLoading = false
}: any) => {
  const { handleCompareView, handleLabelSection } = useApplications();

  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [openComparisonView, setOpenComparisonView] = useState(false);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const [localApplicationList, setLocalApplicationList] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [viewOptions, setViewOptions] = useState<any>(cloneDeep(VIEW_AS_OPTIONS));
  const [currentViewType, setCurrentViewType] = useState(resultsState?.toggleViewType ?? 'grid');
  const [groupByOptions, setGroupByOptions] = useState(cloneDeep(GROUP_BY_OPTIONS));
  const [currentGroupBy, setCurrentGroupBy] = useState(
    resultsState?.selectedGroupOption ??
      cloneDeep(GROUP_BY_OPTIONS.find((opt: any) => opt.selected))
  );
  const [sortOptions, setSortOptions] = useState<any>(cloneDeep(SORT_OPTIONS));
  const [currentSortBy, setCurrentSortBy] = useState(resultsState?.selectedSortOption ?? {});
  const [isLoading, setIsLoading] = useState(false);

  const {
    selectedSources,
    handleSourceChange,
    entityCategory,
    entityCategoryOptions,
    entityValue,
    entityTextOptions
  } = useEntities();

  const [localEntityTextOptions, setLocalEntityTextOptions] = useState<any>(entityTextOptions);
  const [localEntityValue, setLocalEntityValue] = useState(entityValue);
  const [localEntityCategoryOptions, setLocalEntityCategoryOptions] =
    useState<any>(entityCategoryOptions);
  const [localEntityCategory, setLocalEntityCategory] = useState(entityCategory);
  const [applicationData, setApplicationData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string[]>(['']);

  const getNormalizedApplications = (unNormalizedApplications: any) => {
    let apps: any = [];
    const resultSources: any = [];
    Object.keys(unNormalizedApplications)?.forEach(source => {
      resultSources.push(source);
      if (apiDataToCardProps[source]) {
        const sourceApps = unNormalizedApplications[source].results.map((appData: any) =>
          apiDataToCardProps[source]?.(appData)
        );
        apps = [...apps, ...sourceApps];
      }
    });

    return apps;
  };

  const applications = useMemo(() => {
    return getNormalizedApplications(resultsState?.applicationResults);
  }, [resultsState?.applicationResults]);

  useEffect(() => {
    setLocalApplicationList(applications);

    const viewType = resultsState?.toggleViewType ?? 'grid';
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: viewType === opt.id
    }));
    setViewOptions(viewOpts);

    const groupByOption =
      resultsState?.selectedGroupOption ??
      cloneDeep(GROUP_BY_OPTIONS.find((opt: any) => opt.selected));
    const updatedGroupByOptions = groupByOptions.map((opt: any) => ({
      ...opt,
      selected: groupByOption.id === opt.id
    }));
    setGroupByOptions(updatedGroupByOptions);

    const sortByOption = resultsState?.selectedSortOption ?? {};
    const updatedSortOptions = sortOptions.map((opt: any) => ({
      ...opt,
      selected: sortByOption.id === opt.id
    }));
    setSortOptions(updatedSortOptions);
  }, []);

  useEffect(() => {
    setLocalEntityTextOptions(entityTextOptions);
  }, [entityTextOptions]);

  useEffect(() => {
    setLocalEntityCategoryOptions(entityCategoryOptions);
  }, [entityCategoryOptions]);

  const mergedColumnsMapping = useMemo(() => {
    const resultSources: any = [];

    Object.keys(resultsState?.applicationResults)?.forEach(source => {
      resultSources.push(source);
    });

    let mergedColumns: any = [];

    resultSources.forEach((source: string) => {
      mergedColumns = [...mergedColumns, DATAGRID_COLUMN_MAPPING[source]];
    });
    let selectedColumns: any = [];

    selectedColumns =
      mergedColumns.length === 1 ? mergedColumns[0] : intersectionBy(...mergedColumns, 'field');

    // remove matched_submissions if data is empty
    const appArray = Array.isArray(applications)
      ? applications
      : flatMap(applications, (value: any) => value);

    const isEmpty = appArray.every(
      item => Array.isArray(item.matched_submissions) && item.matched_submissions.length === 0
    );

    if (isEmpty) {
      const updatedColumnList = selectedColumns.filter(
        (col: { field: string }) =>
          col.field !== 'matched_submissions' && col.field !== 'matched_submissions_csv'
      );
      return updatedColumnList;
    }

    return selectedColumns;
  }, [resultsState?.applicationResults]);

  useEffect(() => {
    if (resultsState?.decryptedPayload) {
      let term = [];
      if (resultsState?.decryptedPayload?.searchType === 'advanced') {
        term =
          cnfToAdvancedSearch(resultsState?.decryptedPayload?.query)?.advanceSearchRowData?.map(
            (item: any) => item?.query
          ) ?? [];
      } else {
        term = [resultsState?.decryptedPayload?.query];
      }
      setSearchTerm(term);
    }
  }, [resultsState?.decryptedPayload]);

  const applicationSourceDropdown = useMemo(
    () => getApplicationsSourceDropdown(resultsState.labelComparisonSelection),
    [resultsState.labelComparisonSelection]
  );

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const selectedSort = useMemo(() => sortOptions.find((opt: any) => opt.selected), [sortOptions]);

  const isGroupByFilterApplied = useMemo(() => {
    return currentGroupBy?.id !== 'ungroup';
  }, [currentGroupBy]);

  const isAnyOptionSelected = useMemo(() => {
    return (
      (currentSortBy?.selected && currentSortBy?.id !== 'default') ||
      currentViewType === 'list' ||
      currentGroupBy?.id !== 'ungroup'
    );
  }, [selectedSort, viewOptions, currentGroupBy, currentSortBy]);

  const applicationListToShow = useMemo(() => {
    const applicationListCopy = localSearchQuery ? localApplicationList : applications;

    const sortedAndGroupedApplications =
      applicationListCopy?.length > 0
        ? sortAndGroupApplications(
            isGroupByFilterApplied,
            currentGroupBy,
            currentSortBy,
            applicationListCopy as any[]
          )
        : applicationListCopy;

    if (currentViewType === 'list' && !Array.isArray(sortedAndGroupedApplications)) {
      return flatMap(sortedAndGroupedApplications, (value: any) => value);
    }

    return sortedAndGroupedApplications;
  }, [
    localSearchQuery,
    localApplicationList,
    sortOptions,
    currentViewType,
    applications,
    currentGroupBy,
    currentSortBy
  ]);

  const handleApplicationsSearch = async (payload: any) => {
    resultsDispatch({
      type: ResultActions.SET_COMPARISON_APPLICATIONS,
      value: []
    });

    const applicationsResult = await getGenericSearch(payload);

    const resultApplications = applicationsResult?.data?.body?.result || {};

    resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: payload });

    const viewTypeMetadata = applicationsResult?.data?.body?.view_type_metadata ?? {};

    const entityData = entityHandler(applicationsResult?.data?.body?.entities ?? []);

    setLocalEntityTextOptions(entityData);

    resultsDispatch({
      type: ResultActions.SET_ENTITY_LIST,
      value: viewTypeMetadata
    });

    resultsDispatch({
      type: ResultActions.SET_SEARCH_SUGGESTIONS,
      value: applicationsResult?.data?.body?.alternate_results ?? []
    });

    resultsDispatch({
      type: ResultActions.SET_APPLICATION_RESULTS,
      value: resultApplications
    });
  };

  const makeNewSearch = async (dropDownSelectedSources: any) => {
    try {
      setIsLoading(true);
      setLocalSearchQuery('');
      setLocalEntityCategory('');
      setLocalEntityCategoryOptions([]);
      const finalSelectedSources = dropDownSelectedSources ?? selectedSources;
      const convertedSelectedSources = createSourceModulePayloadMapping(finalSelectedSources);
      const newPayload: any = {
        ...resultsState.decryptedPayload,
        source: convertedSelectedSources
      };
      if (newPayload?.category_name) {
        delete newPayload.category_name;
      }

      resultsDispatch({
        type: ResultActions.SET_DECRYPTED_SOURCE,
        value: convertedSelectedSources
      });

      await handleApplicationsSearch(newPayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching search results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyNewEntityText = async (option: any) => {
    try {
      setIsLoading(true);
      setLocalSearchQuery('');
      setLocalEntityCategory('');
      setLocalEntityCategoryOptions([]);
      const entityList: any = [];
      localEntityTextOptions.forEach((item: any) => {
        if (item.value === option.value) {
          entityList.push({
            label: startCase(option.value).replace(/_/g, ''),
            value: option.value,
            selected: true
          });
          setLocalEntityValue(option.value);
        } else {
          entityList.push({
            label: startCase(item.value).replace(/_/g, ''),
            value: item.value,
            selected: false
          });
        }
      });
      setLocalEntityTextOptions(entityList);

      const newPayload: any = {
        ...resultsState.decryptedPayload,
        search_term: option.value
      };

      if (newPayload?.category_name) {
        delete newPayload.category_name;
      }

      await handleApplicationsSearch(newPayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching search results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const applyCategoryEntity = async (option: any) => {
    try {
      setIsLoading(true);
      setLocalSearchQuery('');
      const categoryList: any = [];
      localEntityCategoryOptions.forEach((item: any) => {
        if (item.value === option.value) {
          categoryList.push({
            label: option.label,
            value: option.value,
            selected: true
          });

          setLocalEntityCategoryOptions(item.label);
        } else {
          categoryList.push({
            label: item.label,
            value: item.value,
            selected: false
          });
        }
      });
      setLocalEntityCategoryOptions(categoryList);

      const newPayload = {
        ...resultsState.decryptedPayload,
        query: resultsState?.decryptedPayload?.query,
        ...(resultsState?.decryptedPayload?.search_term && {
          search_term: resultsState?.decryptedPayload?.search_term
        }),
        category_name: option.value
      };

      await handleApplicationsSearch(newPayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching search results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const findApplications = (apps: any, searchText: string) => {
    return apps?.filter((application: any) => {
      const stringValuesMatched = [
        'identifier',
        'groupTitle',
        'sponsor_name',
        'title',
        'approvalPathway',
        'approval_year',
        'schedule',
        'licence_status'
      ].some(field => application?.[field]?.toString()?.toLowerCase()?.includes(searchText));

      if (stringValuesMatched) {
        return true;
      }

      if (application?.formulation?.toString()?.toLowerCase()?.includes(searchText)) {
        return true;
      }

      if (application?.route?.toString()?.toLowerCase()?.includes(searchText)) {
        return true;
      }

      return false;
    });
  };

  const searchApplications = (searchText: string) => {
    const query = searchText?.trim()?.toLowerCase();

    if (!query || query?.length < 1) {
      return applications;
    }

    if (isGroupByFilterApplied) {
      return Object.entries(applications).reduce((acc: any, [year, items]) => {
        // Filter items by checking if the title includes the searchTitle (partial match)
        const matchedItems = findApplications(items, query);

        // If there are matched items, add them under the same year
        if (matchedItems.length > 0) {
          acc[year] = matchedItems;
        }

        return acc;
      }, {});
    }

    return findApplications(applications, query);
  };

  const handleSearch = (searchText: string) => {
    setLocalApplicationList(searchApplications(searchText));
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), [applications]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText: string = e.target.value;
    setLocalSearchQuery(searchText);
    debouncedHandleSearch(searchText);
  };

  const handleViewToggle = (applicationsViewType: string) => {
    const newValue = applicationsViewType === 'grid' ? 'grid' : 'list';
    const viewOpts = viewOptions.map((opt: any) => ({
      ...opt,
      selected: newValue === opt.id
    }));
    setViewOptions(viewOpts);
    setCurrentViewType(newValue);
  };

  const handleGroupBy = (groupOption: any) => {
    const updatedGroupByOptions = groupByOptions.map((opt: any) => ({
      ...opt,
      selected: groupOption.id === opt.id
    }));
    setGroupByOptions(updatedGroupByOptions);
    setCurrentGroupBy({ ...groupOption, selected: true });
  };

  const handleSortBy = (sortOption: any) => {
    const updatedSortOptions = sortOptions.map((opt: any) => ({
      ...opt,
      selected: sortOption.id === opt.id
    }));
    setSortOptions(updatedSortOptions);
    setCurrentSortBy({ ...sortOption, selected: true });
  };

  const fetchS3url = async (path: string, bucket: string) => {
    const record: any = {};
    record.s3_path = decodeURIComponent(path);
    record.s3_bucket = bucket;
    try {
      const response = await getPdfResource({
        ...record
      });
      if (response?.data?.status === 200) {
        return response?.data?.body.s3_url;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const gets3Link = async () => {
    try {
      const applData = [...resultsState.comparisonApplications];
      await Promise.all(
        applData.map(async data => {
          // eslint-disable-next-line no-param-reassign
          data.url = await fetchS3url(data.s3_label_url, data.s3_bucket);
        })
      );

      setApplicationData(applData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching S3 URLs:', error);
    }
  };

  const handleSelectLabels = async () => {
    if (resultsState?.comparisonApplications.length < 2) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Please select at least 2 labels', duration: 3000 }
      });
      return;
    }

    if (resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW) {
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Please wait while we prepare the comparison',
          duration: 3000,
          color: 'warning'
        }
      });
      await gets3Link();
      setOpenComparisonView(true);
    } else {
      setOpenComparisonView(true);
    }
  };

  const applicationsCount = useMemo(() => {
    return Array.isArray(applications)
      ? applications.length
      : flatMap(applications, (value: any) => value).length;
  }, [applications]);

  const applicationsListCount = useMemo(() => {
    return Array.isArray(applicationListToShow)
      ? applicationListToShow.length
      : flatMap(applicationListToShow, (value: any) => value).length;
  }, [applicationListToShow]);

  const handleOnSwap = ({ message, currentIndex, targetIndex }: any) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: 'success', duration: 4000 }
    });
    const comparisonApplicationsNewSeq = [...resultsState.comparisonApplications];
    const temp = comparisonApplicationsNewSeq[currentIndex];
    comparisonApplicationsNewSeq[currentIndex] = comparisonApplicationsNewSeq[targetIndex];
    comparisonApplicationsNewSeq[targetIndex] = temp;
    resultsDispatch({
      type: ResultActions.SET_COMPARISON_APPLICATIONS,
      value: comparisonApplicationsNewSeq
    });
  };

  const comparisonDialogApplications = useMemo(() => {
    return resultsState?.comparisonApplications?.map(item => ({
      ...item,
      source: item?.source,
      application_type: item.applicationType ?? '',
      application_number: item.identifier,
      application_name: item?.title
    }));
  }, [resultsState?.comparisonApplications]);

  const handleDataGridRowSelection = (newRowSelectionModel: any) => {
    const newSelectedApplications = newRowSelectionModel.map((app: any) => app.identifier);

    const alreadySelectedApplications = resultsState?.comparisonApplications.map(
      (app: any) => app.identifier
    );

    const [removed] = difference(alreadySelectedApplications, newSelectedApplications);
    const [added] = difference(newSelectedApplications, alreadySelectedApplications);

    if (removed) {
      const [newComparisonApplication] =
        resultsState?.comparisonApplications?.filter((app: any) => app.identifier === removed) ??
        [];

      handleCompareView(newComparisonApplication, false);
    }

    if (added) {
      const [newComparisonApplication] =
        (applicationListToShow as Array<any>)?.filter((app: any) => app.identifier === added) ?? [];
      handleCompareView(newComparisonApplication, true);
    }
  };

  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={() => onClose()}
      PaperProps={{
        style: {
          height: '98vh',
          width: '100vw',
          backgroundColor: '#fff',
          maxWidth: 'none',
          maxHeight: 'none',
          borderRadius: '8px 8px 0px 0px'
        }
      }}>
      <Stack direction='row' sx={selectionViewStyles.headerWrapper}>
        <Stack spacing={1} sx={selectionViewStyles.titleWrapper}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            {resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.ENHANCED
              ? 'Enhanced Comparison'
              : 'View Latest Labels'}
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={onClose}
            size='small'
            sx={selectionViewStyles.closeIconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={selectionViewStyles.closeButtonIcon} />
          </IconButton>
        </Tooltip>
      </Stack>

      {isLoading || isExternalLoading ? (
        <Loading showTips={false} />
      ) : (
        <>
          <Box mt='16px'>
            <Stack direction='row' justifyContent='space-between' px='28px' height='30px'>
              <Stack direction='row' spacing={4}>
                <TextField
                  sx={selectionViewStyles.searchTextField}
                  placeholder='Find applications'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <MagnifyingGlassIcon sx={selectionViewStyles.searchIcon} />
                      </InputAdornment>
                    )
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    maxLength: 75
                  }}
                  onChange={handleSearchChange}
                  value={localSearchQuery}
                />

                <Button
                  endIcon={<ArrowDownIcon sx={selectionViewStyles.arrowDownIcon} />}
                  sx={selectionViewStyles.sortViewButton}
                  onClick={handleOpenMenu}>
                  <Badge
                    color='primary'
                    variant='dot'
                    invisible={!isAnyOptionSelected}
                    sx={selectionViewStyles.badge}>
                    Sort & View
                  </Badge>
                </Button>
                <RoundedMenu
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleCloseMenu}
                  sx={selectionViewStyles.roundedMenu}>
                  {currentViewType === 'grid' && (
                    <DropDownButton
                      label='Group by'
                      type='action'
                      options={groupByOptions}
                      onOptionSelect={handleGroupBy}
                      highlightOnSelect
                      tooltip='Select to group items'
                      isMenuItem
                      MenuIcon={GroupIcon}
                      showBadge={isGroupByFilterApplied}
                      handleParentMenuClose={handleCloseMenu}
                    />
                  )}
                  <DropDownButton
                    label='Sort by'
                    type='action'
                    options={sortOptions}
                    onOptionSelect={handleSortBy}
                    tooltip='Select to sort items'
                    isMenuItem
                    MenuIcon={SortIconVariant}
                    showBadge={currentSortBy?.selected && currentSortBy?.id !== 'default'}
                    handleParentMenuClose={handleCloseMenu}
                  />
                  <MenuItemWithSubMenu
                    MenuItemIcon={
                      <Badge
                        color='primary'
                        variant='dot'
                        invisible={currentViewType === 'grid'}
                        sx={selectionViewStyles.subMenuBadge}>
                        <ListViewIcon sx={selectionViewStyles.listViewIcon} />
                      </Badge>
                    }
                    MenuItemText='View by'>
                    <MenuWithTickOptions
                      options={viewOptions}
                      onOptionSelect={(opt: any) => {
                        handleViewToggle(opt?.id ?? 'grid');
                        handleCloseMenu();
                      }}
                      isNestedMenu
                    />
                  </MenuItemWithSubMenu>
                </RoundedMenu>
              </Stack>

              <Stack direction='row' spacing={2} alignItems='center'>
                <Box>
                  <Typography
                    sx={{
                      ...selectionViewStyles.selectedText,
                      ...(resultsState?.comparisonApplications?.length > 0 && { color: 'gray.800' })
                    }}>
                    {resultsState?.comparisonApplications?.length} selected
                  </Typography>
                </Box>
                <Divider orientation='vertical' sx={selectionViewStyles.selectionStatsDivider} />
                <Box>
                  <Typography
                    onClick={() => {
                      resultsDispatch({
                        type: ResultActions.SET_COMPARISON_APPLICATIONS,
                        value: []
                      });
                    }}
                    sx={{
                      ...selectionViewStyles.resetText,
                      ...(resultsState?.comparisonApplications?.length > 0 && {
                        color: 'primary.600',
                        cursor: 'pointer'
                      })
                    }}>
                    Reset
                  </Typography>
                </Box>
                <Divider orientation='vertical' sx={selectionViewStyles.selectionStatsDivider} />
                <Button sx={selectionViewStyles.nextButton} onClick={handleSelectLabels}>
                  Next: Select labels
                </Button>
              </Stack>
            </Stack>
            <Divider sx={selectionViewStyles.divider} />
          </Box>
          <Stack direction='row' justifyContent='space-between' px='28px'>
            {showSecondaryNavigation && (
              <Box display='flex' alignItems='center' height='21px'>
                <Box fontSize={14} fontWeight={700}>
                  Showing results for
                </Box>
                <Stack direction='row' alignItems='center'>
                  <Stack direction='row' alignItems='center'>
                    <SourceDropDown
                      selectedSources={selectedSources}
                      handleChange={handleSourceChange}
                      options={applicationSourceDropdown}
                      anchorEl={sourceDropDownAnchorEl}
                      setAnchorEl={setSourceDropDownAnchorEl}
                      type={SOURCE_DROPDOWN_TYPES.APPLICATIONS_VIEW}
                      miniminumOneSelected
                      showApplyButton
                      makeSearch={(dropDownSelectedSources: any) => {
                        makeNewSearch(dropDownSelectedSources);
                      }}
                    />
                  </Stack>
                  <Divider orientation='vertical' sx={selectionViewStyles.secondaryNavSeparator} />
                  <DropDownButton
                    label={
                      localEntityCategory ||
                      `${resultsState?.entities?.category_label} (${applications.length})` ||
                      'Category'
                    }
                    options={localEntityCategoryOptions}
                    onOptionSelect={applyCategoryEntity}
                    tooltip='Switch category'
                  />
                  <Divider orientation='vertical' sx={selectionViewStyles.secondaryNavSeparator} />
                  <DropDownButton
                    label={
                      startCase(localEntityValue)?.replace(/_/g, '') ||
                      startCase(resultsState?.entities?.search_term)?.replace(/_/g, '') ||
                      'Entity Text'
                    }
                    options={localEntityTextOptions}
                    onOptionSelect={applyNewEntityText}
                    tooltip='Switch query'
                  />
                </Stack>
              </Box>
            )}
            <Box sx={selectionViewStyles.resultStatsWrapper}>
              <Typography sx={selectionViewStyles.statsText}>
                Showing <strong>{applicationsListCount}</strong> of{' '}
                <strong>{applicationsCount} applications</strong>
              </Typography>
            </Box>
          </Stack>

          {!openComparisonView && (
            <Stack>
              {currentViewType === 'grid' &&
                // eslint-disable-next-line no-nested-ternary
                ((applicationListToShow as Array<any>)?.length > 0 ||
                (!Array.isArray(applicationListToShow) &&
                  Object.keys(applicationListToShow)?.length > 0) ? (
                  isGroupByFilterApplied ? (
                    applicationListToShow && (
                      <Box sx={selectionViewStyles.expandedGroupWrapper}>
                        {Object.keys(applicationListToShow).map((group: any) => (
                          <ExpandableApplicationGroup
                            key={group}
                            group={removeQuotes(group)}
                            keyMapper={currentGroupBy?.keyMapper}
                            applications={(applicationListToShow as any)[group]}
                            hideApplication={() => {}}
                            triggerChatRIA={() => {}}
                            setFavoriteInHook={() => {}}
                            setInProjectInHook={() => {}}
                            setSubscriptionInHook={() => {}}
                            handleCompareView={handleCompareView}
                            showCompare
                            comparisonApplications={comparisonApplications}
                            labelComparisonLimit={
                              resultsState?.labelComparisonSelectionMethod?.limit ?? 0
                            }
                            handleLabelSection={handleLabelSection}
                            hideCardOptions
                          />
                        ))}
                      </Box>
                    )
                  ) : (
                    <>
                      {applicationListToShow?.filter(
                        (app: any) => app.relevance_score !== 'suggested'
                      ).length === 0 && (
                        <EmptyResult
                          height='20vh'
                          marginTop={2.5}
                          iconStyling={{
                            width: 64,
                            height: 64,
                            fontSize: 30
                          }}
                          firstMessage="Sorry, we couldn't find exact matches for your search."
                          secondMessage='However, check out RIA Suggestions you might like!'
                        />
                      )}
                      <VirtualizedGrid
                        applications={applicationListToShow}
                        hideApplication={[]}
                        labelComparisonSelection
                        handleLabelSection={handleLabelSection}
                        handleCompareView={handleCompareView}
                        comparisonApplications={comparisonApplications}
                        labelComparisonLimit={
                          resultsState?.labelComparisonSelectionMethod?.limit ?? 0
                        }
                        applyUseWindowScroll={false}
                        customHeight='calc(100vh - 178px)'
                        hideCardOptions
                      />
                    </>
                  )
                ) : (
                  <Box textAlign='center' mt={24}>
                    No applications found.
                  </Box>
                ))}
              {currentViewType === 'list' && (
                <Stack sx={selectionViewStyles.dataGridWrapper}>
                  <VivproDatagrid
                    rows={applicationListToShow as any}
                    columnsMapping={mergedColumnsMapping}
                    rowId='identifier'
                    loading={false}
                    csvFileName='Export'
                    isCheckboxSelectionEnabled
                    handleCheckBoxSelection={handleDataGridRowSelection}
                    rowSelectionModel={resultsState.comparisonApplications.map(
                      (app: any) => app.identifier
                    )}
                    dataGridRowSelectionLimit={
                      resultsState?.labelComparisonSelectionMethod?.limit ?? 0
                    }
                  />
                </Stack>
              )}
            </Stack>
          )}

          {openComparisonView &&
            resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW && (
              <PDFComparisonDialog
                sources={sources}
                open={openComparisonView}
                keywords={searchTerm}
                pdfData={applicationData}
                onSwap={handleOnSwap}
                onClose={() => {
                  setOpenComparisonView(false);
                  onClose();
                }}
                onChangeProducts={() => setOpenComparisonView(false)}
              />
            )}

          {openComparisonView &&
            resultsState?.labelComparisonSelectionMethod?.value ===
              COMPARE_LABEL_TYPES.ENHANCED && (
              <ComparisonDialogComp
                open={openComparisonView}
                onClose={() => {
                  setOpenComparisonView(false);
                  onClose();
                }}
                applications={comparisonDialogApplications}
                mode={mode}
                showBackButton
                onBack={() => setOpenComparisonView(false)}
              />
            )}
        </>
      )}
    </Drawer>
  );
};

export default memo(SelectionView);
