const styles = {
  search: {
    width: 'inherit',
    border: 'none',
    outline: 'none',
    display: 'flex',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 '
    }
  },
  searchNav: {
    outline: 'none',
    display: 'flex',
    borderRadius: 2,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    fieldset: {
      display: 'none'
    },
    '& .MuiTextField-root': {
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingTop: '0 ',
      paddingBottom: '0 '
    }
  },
  searchButton: {
    width: '24px',
    height: '24px',
    color: 'white.main',
    background: 'linear-gradient(to right, #3C999A, #47A836)',
    padding: '4.8px',
    borderRadius: '4.8px',
    '&:hover': { background: 'linear-gradient(to right, #358788, #3f9530)' },
    '&:disabled': {
      background: 'linear-gradient(to right, #BDBDBD, #BDBDBD)'
    },
    '& svg': {
      fontSize: '14.4px !important'
    }
  },
  searchButtonAria: {
    color: 'gray.500',
    fontSize: 16,
    padding: '4px'
  },
  greyIcon: {
    color: 'gray.400',
    fontSize: 15
  },
  normalText: {
    flexGrow: 1
  },
  greenText: {
    color: 'primary.700',
    flexGrow: 1
  },
  closeButton: {
    display: 'flex',
    width: '20px',
    height: '20px',
    padding: '4.411px',
    justifyContent: 'center',
    alignItems: 'center',
    mr: '12px',
    '& svg': {
      fill: 'grey.600',
      width: 11,
      height: 11
    }
  },
  divider: {
    mr: '12px',
    my: 'auto',
    height: '16px',
    borderWidth: '1px',
    borderColor: 'grey.300'
  },
  switchRoot: {
    ml: 0,
    mr: '-12px',
    '& .MuiSwitch-root': {
      height: 'auto'
    },
    '&#retain-filters': {
      '& .MuiFormControlLabel-label': {
        color: 'primary.700'
      }
    },
    '& .MuiFormControlLabel-label': {
      color: 'grey.600',
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 400
    },

    '& .Mui-disabled': {
      color: 'grey.400 !important'
    }
  }
};

export default styles;
