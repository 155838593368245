import React, { useContext } from 'react';
import {
  Portal,
  Snackbar,
  Stack,
  Box,
  Tooltip,
  Typography,
  Divider,
  Button,
  Slide
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { TransitionProps } from '@material-ui/core/transitions';
import { capitalize } from 'lodash';
import CloseIcon from '../../../../assets/svgs/Icons/CloseIcon';
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import PDFViewer from '../../../../components/PDFViewer';
import styles from '../../styles/Timeline.styles';
import trimText from '../../../../utils/trimText';
import { getDocumentSourceName } from '../../../SearchResults/utils/documentResultsUtils';

interface DocumentViewerProps {
  openDocument: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  documentTitle: string;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  documentPath: string;
  documentStartDate: string;
}

const Transition = (props: TransitionProps) => {
  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide {...props} direction='left' />;
};

const DocumentViewer = ({
  openDocument,
  setOpenDocument,
  documentTitle,
  setDocumentTitle,
  documentPath,
  documentStartDate
}: DocumentViewerProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const handleChatRia = () => {
    const mappedSourceName = getDocumentSourceName('PRAC');
    const docDetails = {
      'original-file-name': documentTitle,
      'meeting-year': documentStartDate,
      'category-bucket': capitalize(documentPath?.split('/')[3])
    };

    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: documentPath,
        url: documentPath,
        item: {
          ...docDetails
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setOpenDocument(false);
  };

  return (
    <Portal>
      <Snackbar
        TransitionComponent={Transition}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          ...styles.documentViewer.snackBarContainer,
          right: {
            md: '47% !important'
          }
        }}
        open={openDocument}>
        <Stack
          flexDirection='column'
          sx={{ ...styles.documentViewer.pdfContainer, width: { md: '98%' } }}>
          <Box
            display='flex'
            alignItems='center'
            width='100%'
            justifyContent='space-between'
            mb={2.5}>
            <Stack flex='display' alignItems='center' sx={{ pl: 1.9, flexDirection: 'row' }}>
              {documentTitle && (
                <Tooltip title={documentTitle}>
                  <Typography sx={styles.documentViewer.titleText}>
                    {documentTitle ? trimText(documentTitle, 40) : ''}
                  </Typography>
                </Tooltip>
              )}
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ p: 0.7, height: 16 }}
              />
              <Tooltip title='Use Chat RIA to interact with the document'>
                <Button
                  variant='text'
                  disabled={!documentPath}
                  onClick={handleChatRia}
                  sx={styles.documentViewer.chatRiaButton}>
                  Chat RIA
                  <SendIcon sx={styles.documentViewer.chatRiaIcon} />
                </Button>
              </Tooltip>
            </Stack>

            <Box mr={2.5}>
              <CloseIcon
                onClick={() => {
                  setDocumentTitle('');
                  setOpenDocument(false);
                }}
                sx={styles.documentViewer.closeIcon}
              />
            </Box>
          </Box>
          <Box id='pdfBox'>
            <PDFViewer fileUrl={documentPath} defaultScale={1} />
          </Box>
        </Stack>
      </Snackbar>
    </Portal>
  );
};

export default React.memo(DocumentViewer);
