/* eslint-disable camelcase */
/* eslint-disable no-shadow */
// Functional HOC using the useState hook
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { isEqual } from 'lodash';

// custom hooks
import useQuery from '../../../helpers/customHooks/queryParameter';

// utils
import { analyticViewMapping } from '../utils/analyticViewMapping';
import { saveAriaCache } from '../../Aria/utils/ariaCache';
import { processResultsForARIA } from '../utils/processResultsForARIA';
// api
import { getApprovalDateSearch, getQuickSearchResults } from '../../../api/pages/ResultsPage';
import { getAdvanceSearch } from '../../../api/pages/CardsPage';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import ResultsStore from '../../../store/ResultsPage';
import ResultsAction from '../../../store/ResultsPage/actions';
import { getSearchFromId } from '../../../api/pages/UserProfile';

import getMappedFilters, { getSynonymsFilter } from '../utils/getMappedFilter';
import useTimelineData from '../../../components/ClinicalTrials/TimelineCT/hooks/useTimelineData';
import useBusinessIntelligenceAdverseEventsData from '../../../components/ClinicalTrials/hooks/useBusinessIntelligenceAdverseEventsData';
import { getCategoryKey, getFilters } from '../../../components/ClinicalTrials/TimelineCT/utils';
import {
  advancedSearchLogicForResults,
  cnfToAdvancedSearch,
  generateCNFQuery
} from '../../../components/Header/utils/advanceSearchHelpers';

export interface ParameterProps {
  source: string;
  module: string;
  search: string;
  category: string;
  searchType: string;
  searchId: string | null;
}

export interface PayloadProps {
  source: string;
  module: string;
  searchText: string;
  category: string;
  dateRange: any;
  role: string;
}

export const handleCTGraphFetch = (
  searchType: string,
  urlParams: any,
  filters: any[],
  category: string,
  search: string
) => {
  const returnData = {
    cnfOfSelectedQuery: '',
    dateRange: {},
    useSynonyms: false
  };
  let newQuery: any[] = [];
  let dateRange;

  if (searchType === 'advanced') {
    // Decode the CNF to array of categories and their values.
    const advanceSearchRowData = cnfToAdvancedSearch(urlParams.query);

    // Merge the advanced search keys with filters. We need to convert the filters as well to the CNF query. That's we we merge.
    const { dateRange: date, query } = getFilters(filters, advanceSearchRowData);
    newQuery = query;
    if (urlParams.approval_date?.start_date || urlParams.approval_date?.endDate) {
      date.start_date = {
        gte: urlParams.approval_date?.start_date || '',
        lte: urlParams.approval_date?.endDate || ''
      };
    }
    dateRange = date;
    returnData.useSynonyms = urlParams?.use_synonyms;
  } else {
    const defaultQuery = [
      {
        category: getCategoryKey(category),
        categoryKey: getCategoryKey(category),
        searchTerm: search,
        condition: 'contains'
      }
    ];

    const { dateRange: date, query } = getFilters(filters, defaultQuery);
    newQuery = query;
    dateRange = date;
  }

  // Add the quotes around the exact match texts.
  const listDataAfterExactMatch = newQuery.map(({ exactMatch, searchTerm, ...rest }) => ({
    searchTerm: exactMatch ? `"${searchTerm}"` : searchTerm,
    exactMatch,
    ...rest
  }));
  const cnfOfSelectedQuery: any = generateCNFQuery(listDataAfterExactMatch);

  returnData.dateRange = dateRange;
  returnData.cnfOfSelectedQuery = cnfOfSelectedQuery;

  return returnData;
};

/**
 * @param {string} searchId current search id in the pathname.
 * @param {string} newSearchId new search id to be added to the pathname.
 * @param {string} pathname current pathname of the application.
 * @param {string} query any query parameters to be added to the pathname.
 * @returns {string} new pathname with the new search id.
 * @description The function first checks if the current search ID (searchId) is different from the new search ID (search_id). If so, it updates the pathname by replacing the old search ID with the new one. If the new search ID is not already present in the pathname, it is added to the end of the pathname.
 * Next, the function checks if there are any query parameters to be added to the pathname. If so, it appends them to the pathname in the format ?param1=value1&param2=value2.
 * Finally, the function uses the pushState and replaceState methods of the window.history object to update the browser's history and display the updated pathname in the address bar. The function returns the updated pathname..
 */
export const pathNameManipulation = (
  searchId: string,
  newSearchId: string,
  pathname: string,
  query: any
) => {
  let newPath = pathname;
  // check if searchId is different from newSearchId
  if ((!searchId && newSearchId) || !isEqual(searchId as string, newSearchId)) {
    // remove trailing '/' from pathname
    if (newPath.endsWith('/')) {
      newPath = newPath.slice(0, -1);
    }
    // split pathname into an array of substrings
    const pathArray = newPath.split('/');
    // find index of searchId in pathArray
    const indexOfSearch = pathArray.indexOf(searchId);
    if (indexOfSearch !== -1) {
      // replace searchId with newSearchId
      pathArray[indexOfSearch] = newSearchId?.toString();
      newPath = pathArray.join('/');
    } else {
      // add search_id to the end of pathname
      newPath = `${newPath}/${newSearchId}`;
    }
  }
  // check if there are any query parameters
  if (query.toString()) {
    // append query parameters to pathname
    newPath = `${newPath}?${query}`;
  }
  // update browser's history and display new pathname in address bar
  window.history.replaceState(null, '', newPath);
  return newPath;
};
const withFetchingSearchResults = (WrappedComponent: React.FunctionComponent) => {
  return () => {
    const { dispatch } = useContext(GlobalStore) as any;
    // context store for aria state management
    const { resultsDispatch } = useContext(ResultsStore) as any;

    // get the query arguments from url
    const query = useQuery();
    const { pathname } = useLocation();
    // get the url parameters
    // @ts-ignore
    const { module, source, category, search, searchType, searchId } = useParams<ParameterProps>();
    const { getTimelineData } = useTimelineData();
    const { fetchCTBusinessSearchResultsFromId, fetchCTAdverseEventsResultsFromId } =
      useBusinessIntelligenceAdverseEventsData();

    useEffect(() => {
      resultsDispatch({ type: ResultsAction.SET_LOADING, value: true });
      const clearStates = () => {
        // clear states on new searches
        resultsDispatch({ type: ResultsAction.SET_FILTERS, value: {} });
        resultsDispatch({ type: ResultsAction.SET_SELECTED_SORT, value: 'default' });
        resultsDispatch({ type: ResultsAction.SET_RESULTS, value: [] });

        resultsDispatch({ type: ResultsAction.SET_RESULTS_ID, value: 0 });
        resultsDispatch({ type: ResultsAction.SET_IS_FAVORITE, value: false });
        resultsDispatch({ type: ResultsAction.SET_IN_PROJECT, value: [] });

        resultsDispatch({ type: ResultsAction.SET_SYNONYMS_FILTER, value: [] });

        resultsDispatch({ type: ResultsAction.SET_ANALYTIC_VIEW_RESULTS, value: {} });

        resultsDispatch({ type: ResultsAction.SET_HIDE_APPLICATIONS, value: [] });
        resultsDispatch({ type: ResultsAction.SET_IS_FAVORITE, value: false });
        resultsDispatch({ type: ResultsAction.SET_IN_PROJECT, value: [] });
      };
      // clear states on new searches
      clearStates();
      const getData = async () => {
        const user = await Auth.currentUserInfo();
        try {
          // dispatch({ type: GlobalActions.SET_NEW_SEARCH, value: true });
          resultsDispatch({
            type: ResultsAction.SET_ERROR,
            value: false
          });
          let res = null;

          if (searchId) {
            if (source.toLowerCase() === 'ct') {
              fetchCTBusinessSearchResultsFromId(searchId);
              fetchCTAdverseEventsResultsFromId(searchId);
            }
            res = await getSearchFromId(searchId);
            if (source.toLowerCase() === 'ct') {
              if (res?.status === 200) {
                const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
                  searchType,
                  query,
                  res?.data?.body?.filters || [],
                  category || '',
                  search || ''
                );
                getTimelineData(cnfOfSelectedQuery, '', dateRange, false, useSynonyms);
              }
            }
          } else {
            // check for advance and quick search
            // eslint-disable-next-line no-lonely-if
            if (searchType === 'advance') {
              const newAdvanceSearch = advancedSearchLogicForResults(query, module, user);
              res = await getAdvanceSearch(newAdvanceSearch, module, source?.toLowerCase());
            } else {
              let dateRange: { [key: string]: string } = {};
              if (['approval_date', 'first_approval_date', 'sales_date'].includes(category)) {
                dateRange = {
                  startDate: query.get('startDate') || '',
                  endDate: query.get('endDate') || ''
                };
              }
              const payload: PayloadProps = {
                category,
                source: source?.toLowerCase(),
                searchText: search,
                module,
                dateRange,
                role: user.attributes['custom:role']
              };
              if (['approval_date', 'first_approval_date', 'sales_date'].includes(category)) {
                res = await getApprovalDateSearch(payload);
              } else {
                // get quick search results from rest api
                res = await getQuickSearchResults(payload);
              }
            }
          }
          // checks if response exists else display approriate error message
          if (res?.status === 200) {
            const { results, search_id, isFavorite, inProjects } = res.data.body;
            if (!results?.length) {
              resultsDispatch({
                type: ResultsAction.SET_ERROR,
                value: 204
              });
            } else {
              resultsDispatch({
                type: ResultsAction.SET_ERROR,
                value: false
              });
              // Clear sort selection on change of results.
              resultsDispatch({ type: ResultsAction.SET_SELECTED_SORT, value: 'default' });
              resultsDispatch({ type: ResultsAction.SET_IS_FAVORITE, value: isFavorite });
              resultsDispatch({ type: ResultsAction.SET_IN_PROJECT, value: inProjects });
              resultsDispatch({ type: ResultsAction.SET_RESULTS, value: results });
              resultsDispatch({ type: ResultsAction.SET_RESULTS_ID, value: search_id });
              // set analytic view data
              const analyticViewData = analyticViewMapping(results);

              resultsDispatch({
                type: ResultsAction.SET_ANALYTIC_VIEW_RESULTS,
                value: analyticViewData
              });
              if (searchId && res.data.body.filters) {
                resultsDispatch({
                  type: ResultsAction.SET_FILTERS,
                  value: getMappedFilters(res.data.body.filters)
                });

                resultsDispatch({
                  type: ResultsAction.SET_SYNONYMS_FILTER,
                  value: getSynonymsFilter(res.data.body.filters)
                });
              }
              const newPath = pathNameManipulation(searchId as string, search_id, pathname, query);
              // stores the list of application number required for this search for aria
              saveAriaCache(
                processResultsForARIA(source, results),
                `${newPath}`,
                source?.toLowerCase()
              );
              // Setting up the navigation for REG360.
              const navigate = {
                url: newPath,
                search: decodeURIComponent(search)
              };
              sessionStorage.setItem('reg360Navigation', JSON.stringify(navigate));
            }
          }

          if (res?.status === 404 || res?.status === 204) {
            // clear states on new searches
            clearStates();
            resultsDispatch({
              type: ResultsAction.SET_ERROR,
              value: res?.status
            });
            resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });
          }

          if (res?.status === 429) {
            if (res.data.body?.includes(':')) {
              dispatch({
                type: GlobalActions.SET_SERVICE_RESTRICTION_DETAILS,
                value: { message: res.data.body?.split(':')[1], status: true, closeBtn: false }
              });
            }
          }
          if (res?.status === 500 || res?.status === 400) {
            // clear states on new searches
            clearStates();
            resultsDispatch({
              type: ResultsAction.SET_ERROR,
              value: 500
            });
            resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });
          }
          dispatch({ type: GlobalActions.SET_NEW_SEARCH, value: false });
          resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });
        } catch (e) {
          // clear states on new searches
          clearStates();
          resultsDispatch({
            type: ResultsAction.SET_ERROR,
            value: 500
          });
          resultsDispatch({ type: ResultsAction.SET_LOADING, value: false });

          // eslint-disable-next-line no-console
          console.error(e);
        }
      };

      getData();
    }, [
      query.get('startDate'),
      query.get('endDate'),
      query.get('cnfQuery'),
      query.get('useSynonyms'),
      module,
      category,
      source,
      search,
      searchId
    ]);

    return <WrappedComponent />;
  };
};

export default withFetchingSearchResults;
