export const adcommCommitteeMapping = {
  All: 'All Committees',
  NDAC: 'Nonprescription Drug Advisory Committee',
  ODAC: 'Oncologic Drugs Advisory Committee',
  AADAC: 'Anesthetic and Analgesic Drug Products Advisory Committee',
  AMDAC: 'Antimicrobial Drugs Advisory Committee',
  AVDAC: 'Antiviral Drugs Advisory Committee',
  ARDAC: 'Arthritis Advisory Committee',
  BRUDAC: 'Bone, Reproductive and Urologic Drugs Advisory Committee',
  CRDAC: 'Cardiovascular and Renal Drugs Advisory Committee',
  DODAC: 'Dermatologic and Ophthalmic Drugs Advisory Committee',
  DSRMDAC: 'Drug Safety and Risk Management Advisory Committee',
  EMDAC: 'Endocrinologic and Metabolic Drugs Advisory Committee',
  GIDAC: 'Gastrointestinal Drugs Advisory Committee',
  MIDAC: 'Medical Imaging Drugs Advisory Committee',
  PCNSDAC: 'Peripheral and Central Nervous System Drugs Advisory Committee',
  PSCPDAC: 'Pharmaceutical Science and Clinical Pharmacology Advisory Committee',
  PCAC: 'Pharmacy Compounding Advisory Committee',
  PPDAC: 'Psychopharmacologic Drugs Advisory Committee',
  PADAC: 'Pulmonary-Allergy Drugs Advisory Committee'
};

/**
 * function to generate navigation for back button on aria
 * @param {object} query search term for the cards results page
 * @param {string} module module name
 * @param {string} source source name
 * @returns string that will be shown on the back button on aria
 */
const generateNavigationText = (query, module) => {
  const moduleMapping = {
    bla: 'Biologics Module',
    core: 'Core Module',
    '505b2': '505b2 Module',
    advisoryCommittee: 'Adcomm Module',
    guidanceDocuments: 'Guidance Documents Module',
    ema_guidance: 'EMA Guidance Documents Module',
    canada_guidance: 'CA Guidance Documents Module',
    canada_consultation: 'CA Counsultation Documents Module',
    'fda-letters': 'FDA Warnings and Untitled Letters Module',
    htaDocuments: 'HTA Documents Module',
    clinicalTrials: 'Core Module',
    pediatricsEma: 'EMA PIPs',
    pediatrics: 'FDA Written Requests',
    chmp: 'CHMP',
    'dd-tools': 'Drug Development Tools',
    euctr: 'EU Clinical Trials Register',
    ema_orphan: 'EMA Orphan Designations',
    pdco: 'EU PDCO',
    prac: 'EU PRAC'
  };
  if (!query.get('resultsPage') || module === 'htaDocuments' || module === 'chmp') {
    return moduleMapping[module];
  }

  if (query.get('favorites')) {
    return 'Favorites';
  }

  if (query.get('projectName')) {
    return `Project - ${query.get('projectName')}`;
  }

  if (module === 'advisoryCommittee') {
    return adcommCommitteeMapping[query.get('searchTerm')] || 'Adcomm Meetings';
  }

  if (!query.get('resultsPage') || module === 'pediatricsEma') {
    return moduleMapping[module];
  }
  if (!query.get('resultsPage') || module === 'pediatrics') {
    return moduleMapping[module];
  }

  if (!query.get('resultsPage') || module === 'guidanceDocuments') {
    return moduleMapping[module];
  }
  if (!query.get('resultsPage') || module === 'ema_guidance') {
    return moduleMapping[module];
  }
  if (query.get('searchTerm') && query.get('searchTerm') === 'rangeSearch') {
    return 'Approval date search';
  }
  return `${query.get('searchTerm') || 'Advanced'} Search`;
};

export default generateNavigationText;
