const Actions = {
  SET_LOADING: 'SET_LOADING',
  SET_SELECTED_DOCUMENT: 'SET_SELECTED_DOCUMENT',
  SET_DOCUMENT_RESULTS: 'SET_DOCUMENT_RESULTS',
  SET_APPLICATION_RESULTS: 'SET_APPLICATION_RESULTS',
  SET_VIEW_TYPE: 'SET_VIEW_TYPE',
  SET_TOGGLE_VIEW_TYPE: 'SET_TOGGLE_VIEW_TYPE',
  SET_DECRYPTED_SOURCE: 'SET_DECRYPTED_SOURCE',
  SET_LABEL_COMPARISON_SELECTION: 'SET_LABEL_COMPARISON_SELECTION',
  SET_SEARCH_IDS: 'SET_SEARCH_IDS',
  SET_IS_FAVORITE: 'SET_IS_FAVORITE',
  SET_IS_SUBSCRIBED: 'SET_IS_SUBSCRIBED',
  SET_ENTITY_LIST: 'SET_ENTITY_LIST',
  SET_RESULTS_ID: 'SET_RESULTS_ID',
  SET_IN_PROJECTS: 'SET_IN_PROJECTS',
  SET_DECRYPTED_PAYLOAD: 'SET_DECRYPTED_PAYLOAD',
  SET_HIDDEN_APPLICATIONS: 'SET_HIDDEN_APPLICATIONS',
  SET_APPLICATIONS: 'SET_APPLICATIONS',
  SET_IS_GROUP_BY_FILTER_APPLIED: 'SET_IS_GROUP_BY_FILTER_APPLIED',
  TRIGGER_RESTORE_APPLICATIONS: 'TRIGGER_RESTORE_APPLICATIONS',
  SET_SELECTED_GROUP_OPTION: 'SET_SELECTED_GROUP_OPTION',
  SET_SELECTED_SORT_OPTION: 'SET_SELECTED_SORT_OPTION',
  SET_FILTERS: 'SET_FILTERS',
  SET_SYNONYMS: 'SET_SYNONYMS',
  SET_SHOW_UNIQUE_RESULTS: 'SET_SHOW_UNIQUE_RESULTS',
  SET_EXPLORE_PAGE_RESULTS_COUNT: 'SET_EXPLORE_PAGE_RESULTS_COUNT',
  SET_EXPLORE_PAGE_MAPPER: 'SET_EXPLORE_PAGE_MAPPER',
  SET_COMPARISON_APPLICATIONS: 'SET_COMPARISON_APPLICATIONS',
  SET_CT_RESULTS: 'SET_CT_RESULTS',
  SET_TRIALS_DATA: 'SET_TRIALS_DATA',
  SET_TRIALS_LOADING: 'SET_TRIALS_LOADING',
  SET_TRIALS_ERROR: 'SET_TRIALS_ERROR',
  SET_CT_BUSINESS_INTELLIGENCE: 'SET_CT_BUSINESS_INTELLIGENCE',
  SET_CT_BUSINESS_INTELLIGENCE_LOADING: 'SET_CT_BUSINESS_INTELLIGENCE_LOADING',
  SET_CT_ADVERSE_EVENTS_LOADING: 'SET_CT_ADVERSE_EVENTS_LOADING',
  SET_CT_ADVERSE_EVENTS: 'SET_CT_ADVERSE_EVENTS',
  SET_IS_CONTENT_LOADING: 'SET_IS_CONTENT_LOADING',
  SET_ORIGINAL_TEXT_ENABLED: 'SET_ORIGINAL_TEXT_ENABLED',
  SET_IS_ARIA_OPENED: 'SET_IS_ARIA_OPENED',
  SET_ARIA_ENTITY_IDS: 'SET_ARIA_ENTITY_IDS',
  SET_DOCUMENT_LOADER: 'SET_DOCUMENT_LOADER',
  SET_ARIA_FILTERS: 'SET_ARIA_FILTERS',
  SET_ARIA_RESPONSE: 'SET_ARIA_RESPONSE',
  SET_SELECTED_ARIA_RESPONSE: 'SET_SELECTED_ARIA_RESPONSE',
  SET_CACHED_ARIA_RESPONSE: 'SET_CACHED_ARIA_RESPONSE',
  SET_ARIA_RESPONSE_LOADING: 'SET_ARIA_RESPONSE_LOADING',
  SET_ARIA_RESPONSE_ANSWER_TEXT: 'SET_ARIA_RESPONSE_ANSWER_TEXT',
  SET_EXPLORE_RESULTS_OPEN: 'SET_EXPLORE_RESULTS_OPEN',
  SET_COLLATED_SUMMARY_SELECTION: 'SET_COLLATED_SUMMARY_SELECTION',
  SET_COLLATED_SUMMARY_APPLICATIONS: 'SET_COLLATED_SUMMARY_APPLICATIONS',
  SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS: 'SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS',
  SET_COLLATED_SUMMARY_SELECTED_CATEGORIES: 'SET_COLLATED_SUMMARY_SELECTED_CATEGORIES',
  SET_CT_STATS: 'SET_CT_STATS',
  SET_CT_FILTERS: 'SET_CT_FILTERS',
  SET_CT_PAGINATION: 'SET_CT_PAGINATION',
  SET_CT_IS_LOADING: 'SET_CT_IS_LOADING',
  SET_CT_DATA_GRID_FILTERS: 'SET_CT_DATA_GRID_FILTERS',
  SET_CT_SORT_BY: 'SET_CT_SORT_BY',
  SET_CT_EXPORT: 'SET_CT_EXPORT',
  SET_TOP_10_SUMMARY: 'SET_TOP_10_SUMMARY',
  SET_SHOW_TOP_10_SUMMARY: 'SET_SHOW_TOP_10_SUMMARY',
  SET_TOP_10_SUMMARY_LOADING: 'SET_TOP_10_SUMMARY_LOADING',
  SET_RIA_RESPONSE_TITLE: 'SET_RIA_RESPONSE_TITLE',
  SET_RESULT_IDS_IN_VIEW: 'SET_RESULT_IDS_IN_VIEW',
  SET_COLLATED_SUMMARY_DOWNLOAD_TYPE: 'SET_COLLATED_SUMMARY_DOWNLOAD_TYPE',
  SET_LABEL_COMPARISON_SELECTION_METHOD: 'SET_LABEL_COMPARISON_SELECTION_METHOD',
  SET_IS_SOURCE_CHANGED_WITH_FILTERS: 'SET_IS_SOURCE_CHANGED_WITH_FILTERS',
  SET_IS_NEW_ADVANCE_SEARCH: 'SET_IS_NEW_ADVANCE_SEARCH',
  SET_SEARCH_SUGGESTIONS: 'SET_SEARCH_SUGGESTIONS',
  SET_ADVANCE_SEARCH_ALERT_MSG: 'SET_ADVANCE_SEARCH_ALERT_MSG',
  SET_IS_SEARCH_BY_SEARCH_ID: 'SET_IS_SEARCH_BY_SEARCH_ID',
  SET_IS_NEW_SEARCH_TERM: 'SET_IS_NEW_SEARCH_TERM',
  SET_SCROLL_RESULT_ID: 'SET_SCROLL_RESULT_ID'
};

export default Actions;
