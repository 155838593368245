import { useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep, Dictionary } from 'lodash';
import { useParams } from 'react-router-dom';

import { getSearchApplicationResults } from '../../../api/pages/ResultsPage';
import { apiDataToCardProps, GROUP_BY_OPTIONS, SORT_OPTIONS } from '../constants';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import getApplicationStats from '../utils/getApplicationStats';
import { sortAndGroupApplications } from '../utils/sortAndGroup';
import AuthContext from '../../../store/Auth/AuthProvider';
import getUserDetails from '../utils/getUserDetails';

const useApplications = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext);

  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [feature, setFeature] = useState<string>('');

  const [sortOptions, setSortOptions] = useState<any>(cloneDeep(SORT_OPTIONS));
  const [groupByOptions, setGroupByOptions] = useState<any>(cloneDeep(GROUP_BY_OPTIONS));
  const [applications, setApplications] = useState<any[]>([]);
  const [resultedSources, setResultedSources] = useState<any[]>([]);

  const { payload }: any = useParams();
  const {
    search_term: searchTerm,
    source: sources,
    search_type: searchType
  }: any = useMemo(() => {
    if (!payload) {
      return {
        search_term: '',
        source: '',
        search_type: ''
      };
    }
    return decodeBase64ToObject(payload);
  }, [payload]);

  const setHiddenApplications = (val: any) => {
    resultsDispatch({
      type: ResultActions.SET_HIDDEN_APPLICATIONS,
      value: val
    });
  };

  useEffect(() => {
    const sourceWiseApps = resultsState.applicationResults;
    const searchObject: any = decodeBase64ToObject(payload);
    if (searchObject && 'feature' in searchObject && searchObject.feature === 'comparsion') {
      setFeature('comparsion');
    } else if (
      searchObject &&
      'us' in searchObject.source &&
      searchObject.source.us.includes('505b2')
    ) {
      setFeature('505b2');
    }
    let apps: any = [];
    const resultSources: any = [];
    Object.keys(sourceWiseApps).forEach(source => {
      resultSources.push(source);
      if (apiDataToCardProps[source]) {
        const sourceApps = sourceWiseApps[source].results.map((appData: any) =>
          apiDataToCardProps[source]?.(appData)
        );
        apps = [...apps, ...sourceApps];
      }
    });
    setResultedSources(resultSources);
    if (resultsState.collatedSummaryApplications.length !== 0) {
      const selectedApps = resultsState.collatedSummaryApplications
        .map(element => {
          const appIndex = apps.findIndex((app: any) => app.identifier === element.number);
          if (appIndex !== -1) {
            return {
              ...apps[appIndex],
              labelSelectionIdentifier: `${element.source}-${element.number}-${element.section}`
            };
          }
          return null;
        })
        .filter(app => app !== null);

      setApplications(selectedApps);
    } else {
      setApplications(apps);
    }

    setHiddenApplications([]);
  }, [resultsState.applicationResults, resultsState.collatedSummaryApplications]);

  const getApplications = async () => {
    const userDetails = getUserDetails(currentUser);
    const response = await getSearchApplicationResults({
      source: sources,
      query: searchTerm,
      view_type: 'application',
      filters: resultsState.filters,
      userDetails
    });
    const sourceWiseApps = response.data.body.result;
    resultsDispatch({
      type: ResultActions.SET_APPLICATION_RESULTS,
      value: sourceWiseApps
    });
  };

  const sortBy = (option: any): void => {
    const index = sortOptions.findIndex((opt: any) => opt.id === option.id);
    sortOptions[index].selected = true;
    resultsDispatch({
      type: ResultActions.SET_SELECTED_SORT_OPTION,
      value: sortOptions[index]
    });
    setSortOptions(
      sortOptions.map((opt: any, _index: number) => ({ ...opt, selected: index === _index }))
    );
  };

  const groupBy = async (option: any) => {
    const index = groupByOptions.findIndex((opt: any) => opt.id === option.id);
    const groupByOpts = groupByOptions.map((opt: any, _index: number) => ({
      ...opt,
      selected: index === _index
    }));
    setGroupByOptions(groupByOpts);
    resultsDispatch({
      type: ResultActions.SET_SELECTED_GROUP_OPTION,
      value: groupByOpts[index]
    });
    resultsDispatch({
      type: ResultActions.SET_IS_GROUP_BY_FILTER_APPLIED,
      value: groupByOpts[index].id !== 'ungroup'
    });
  };

  const hideApplication = ({
    source,
    identifier
  }: {
    source: string;
    identifier: string;
  }): void => {
    const applicationToHideIndex = applications.findIndex(
      x => x.source === source && x.identifier === identifier
    );
    if (applicationToHideIndex === -1) return;
    setHiddenApplications([
      ...(resultsState?.hiddenApplications ?? []),
      {
        ...applications[applicationToHideIndex],
        isChecked: false
      }
    ]);
    const newApplications = [...applications];
    newApplications.splice(applicationToHideIndex, 1);
    setApplications(newApplications);
  };

  const selectHiddenApplication = ({
    source,
    identifier,
    checked
  }: {
    source: string;
    identifier: string;
    checked: boolean;
  }): void => {
    if (!resultsState?.hiddenApplications || !source) return;
    const applicationToHideIndex = resultsState?.hiddenApplications.findIndex(
      x => x.source === source && x.identifier === identifier
    );
    if (applicationToHideIndex === -1) return;
    const newHiddenApplications = [...(resultsState?.hiddenApplications ?? [])];
    newHiddenApplications[applicationToHideIndex].isChecked = checked;
    setHiddenApplications(newHiddenApplications);
  };

  const restoreApplications = (): void => {
    const newHiddenApps = [
      ...(resultsState?.hiddenApplications?.filter((app: any) => !app.isChecked) ?? [])
    ];
    const newApplications = [
      ...applications,
      ...(resultsState?.hiddenApplications
        ?.filter((app: any) => app.isChecked)
        ?.map(({ isChecked, ...keepAttrs }) => keepAttrs) ?? [])
    ];
    setHiddenApplications(newHiddenApps);
    setApplications(newApplications);
  };

  const viewApplications: any[] | Dictionary<any[]> = useMemo(
    () =>
      sortAndGroupApplications(
        resultsState.isGroupByFilterApplied,
        resultsState.selectedGroupOption,
        resultsState.selectedSortOption,
        applications
      ),
    [resultsState?.selectedGroupOption, resultsState?.selectedSortOption, applications]
  );

  const viewStats = useMemo(
    () => getApplicationStats(applications, Object.keys(resultsState.applicationResults)),
    [resultsState.applicationResults, applications]
  );

  const triggerChatRIA = (source: string, identifier: string) => {
    let newAppl: any[] = [];

    const apps = applications ?? [];
    const selectedApplicationIndex = apps?.findIndex(
      (x: { source: string; identifier: string }) =>
        x.source === source && x.identifier === identifier
    );
    const updatedApplicationList = apps.filter((app: any) => app.identifier !== identifier);
    newAppl = [apps[selectedApplicationIndex], ...updatedApplicationList];
    dispatch({ type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM, value: 'application' });
    dispatch({ type: GlobalActions.SET_APPLICATION_SOURCE, value: source });
    dispatch({ type: GlobalActions.SET_APPLICATION_LIST, value: newAppl });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };
  useEffect(() => {
    if (resultsState?.triggerRestoreApplications) {
      restoreApplications();
    }
  }, [resultsState?.triggerRestoreApplications]);

  useEffect(() => {
    // Don't set default group by option if label comparison is selected or if group by filter is applied
    // This is to avoid resetting the group by option when user navigates to label comparison
    if (resultsState?.labelComparisonSelection) {
      // maintain the selected group by option if user navigates to label comparison
      const selectedGroupByIndex = groupByOptions.findIndex(
        (opt: any) => opt.id === resultsState?.selectedGroupOption?.id
      );
      const groupByOpts = groupByOptions.map((opt: any, _index: number) => ({
        ...opt,
        selected: selectedGroupByIndex === _index
      }));
      setGroupByOptions(groupByOpts);

      // maintain the selected sort by option if user navigates to label comparison
      const selectedSortByIndex = sortOptions.findIndex(
        (opt: any) => opt.id === resultsState?.selectedSortOption?.id
      );
      const sortByOpts = sortOptions.map((opt: any, _index: number) => ({
        ...opt,
        selected: selectedSortByIndex === _index
      }));
      setSortOptions(sortByOpts);
      return;
    }

    resultsDispatch({
      type: ResultActions.SET_SELECTED_GROUP_OPTION,
      value: cloneDeep(GROUP_BY_OPTIONS.find((opt: any) => opt.selected))
    });

    resultsDispatch({
      type: ResultActions.SET_IS_GROUP_BY_FILTER_APPLIED,
      value: false
    });
  }, []);

  const setFavorite = ({
    source,
    identifier,
    isFavorite
  }: {
    source: string;
    identifier: string;
    isFavorite: boolean;
  }) => {
    const selectedApplicationIndex = applications?.findIndex(
      (x: { source: string; identifier: string }) =>
        x.source === source && x.identifier === identifier
    );
    if (selectedApplicationIndex === -1) return;
    const newApplications = [...applications];
    newApplications[selectedApplicationIndex].isFavorite = isFavorite;
    setApplications(newApplications);
  };

  const setInProject = ({
    source,
    identifier,
    project
  }: {
    source: string;
    identifier: string;
    project: any;
  }) => {
    const selectedApplicationIndex = applications?.findIndex(
      (x: { source: string; identifier: string }) =>
        x.source === source && x.identifier === identifier
    );
    if (selectedApplicationIndex === -1) return;
    const newApplications = [...applications];
    if (project?.inProject) {
      newApplications[selectedApplicationIndex].inProjects = newApplications[
        selectedApplicationIndex
      ].inProjects.filter(
        (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
      );
    } else {
      newApplications[selectedApplicationIndex].inProjects = [
        ...newApplications[selectedApplicationIndex].inProjects,
        { id: project?.project_id ?? project?.id, name: project?.name }
      ];
    }
    setApplications(newApplications);
  };

  const setIsSubscribed = ({
    source,
    identifier,
    isSubscribed
  }: {
    source: string;
    identifier: string;
    isSubscribed: boolean;
  }) => {
    const selectedApplicationIndex = applications?.findIndex(
      (x: { source: string; identifier: string }) =>
        x.source === source && x.identifier === identifier
    );
    if (selectedApplicationIndex === -1) return;
    const newApplications = [...applications];
    newApplications[selectedApplicationIndex].isSubscribed = isSubscribed;
    setApplications(newApplications);
  };

  const exitCompareMode = () => {
    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
      value: false
    });
    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION_METHOD,
      value: null
    });
  };

  const clearComparison = () => {
    resultsDispatch({
      type: ResultActions.SET_COMPARISON_APPLICATIONS,
      value: []
    });
  };
  const removeFromComparison = (keyToRemove: any) => {
    const pdfIndex = resultsState?.comparisonApplications.findIndex(
      (item: { identifier: string }) => {
        return item?.identifier === keyToRemove;
      }
    );

    resultsDispatch({
      type: ResultActions.SET_COMPARISON_APPLICATIONS,
      value: [
        ...(resultsState?.comparisonApplications?.slice(0, pdfIndex) || []),
        ...(resultsState?.comparisonApplications?.slice(pdfIndex + 1) || [])
      ]
    });
  };

  const handleCompareView = async (data: any, isAdd: boolean) => {
    if (isAdd) {
      // eslint-disable-next-line no-param-reassign
      data.heading = `${data.identifier} - ${data.title}`;
      resultsDispatch({
        type: ResultActions.SET_COMPARISON_APPLICATIONS,
        // eslint-disable-next-line no-unsafe-optional-chaining
        value: [...resultsState?.comparisonApplications, data]
      });
    } else {
      const pdfIndex = resultsState?.comparisonApplications.findIndex(
        (item: { identifier: string }) => {
          return item?.identifier === data?.identifier;
        }
      );
      resultsDispatch({
        type: ResultActions.SET_COMPARISON_APPLICATIONS,
        value: [
          ...(resultsState?.comparisonApplications?.slice(0, pdfIndex) || []),
          ...(resultsState?.comparisonApplications?.slice(pdfIndex + 1) || [])
        ]
      });
    }
  };

  const handleLabelSection = async (data: any, isAdd: boolean) => {
    if (isAdd) {
      resultsDispatch({
        type: ResultActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS,
        value: [...(resultsState?.collatedSummarySelectedApplications || []), data]
      });
    } else {
      const applIndex = resultsState?.collatedSummarySelectedApplications.findIndex(
        (item: { identifier: string }) => {
          return item?.identifier === data?.identifier;
        }
      );
      resultsDispatch({
        type: ResultActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS,
        value: [
          ...(resultsState?.collatedSummarySelectedApplications?.slice(0, applIndex) || []),
          ...(resultsState?.collatedSummarySelectedApplications?.slice(applIndex + 1) || [])
        ]
      });
    }
  };
  return {
    applications: viewApplications,
    sortOptions,
    groupByOptions,
    isGroupByFilterApplied: resultsState?.isGroupByFilterApplied,
    selectedGroupOption: resultsState?.selectedGroupOption,
    stats: viewStats,
    getApplications,
    sortBy,
    groupBy,
    resultedSources,
    searchTerm,
    appView: resultsState.toggleViewType,
    hideApplication,
    selectHiddenApplication,
    restoreApplications,
    triggerChatRIA,
    setFavorite,
    setInProject,
    setIsSubscribed,
    searchType,
    labelComparisonSelection: resultsState?.labelComparisonSelection,
    comparisonApplications: resultsState?.comparisonApplications,
    exitCompareMode,
    clearComparison,
    removeFromComparison,
    handleCompareView,
    feature,
    sources,
    handleLabelSection,
    selectedLabelSelection: resultsState?.collatedSummarySelectedApplications,
    isDownloadLabelSummaryOn:
      resultsState?.collatedSummarySelectedApplications?.length > 0 ||
      resultsState?.collatedSummaryApplications?.length > 0
  };
};

export default useApplications;
