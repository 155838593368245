import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// mui
import { Box, Button, Divider, Link, Stack } from '@mui/material';

// components
import { isEmpty, isEqual } from 'lodash';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import SearchBarAutoComplete from './SearchBarAutoComplete';
import SourceDropDown from './SourceDropDown';

// custom icons
import SearchIcon from '../../../assets/svgs/Icons/SearchIcon';

// hooks
import useSearchSuggestions from '../hooks/useSearchSuggestions';

// utils
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';

// constants
import { SOURCE_MENU_ITEMS, tipsForBetterSearchLink } from '../const';

// types
import { SourceDropdown } from '../types';

// styles
import styles from '../styles/SearchBar.styles';
import { filterOptions } from '../../../components/Header/utils/searchUtils';
import DocumentPageCount from './DocumentPageCount';
import AdvancedSearch from './AdvancedSearch';
import { createSourceModulePayloadMapping, getDefaultHomePageSourceDropDown } from '../utils';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';

const SearchBar = ({
  type,
  selectedSourcesValue
}: {
  type?: 'home' | 'nav';
  selectedSourcesValue?: SourceDropdown[];
}) => {
  const location = useLocation();
  // history
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);
  const [searchHighlighted, setSearchHighlighted] = useState(false);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [advancedSearchActive, setAdvancedSearchActive] = useState(false);
  const [disableRetainFilter, setDisableRetainFilter] = useState(false);
  const [retainFilter, setRetainFilter] = useState(false);
  const [initialSource, setInitialSource] = useState<SourceDropdown[]>([]);
  const onRetainFilterChange = (e: any) => {
    setRetainFilter(e.target.checked);
  };

  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const searchWrapperRef = React.useRef(null);

  // Search Autocomplete.
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);

  const sourceDropDownOpen = Boolean(sourceDropDownAnchorEl);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const handleSourceChange = useCallback(
    (values: SourceDropdown[]) => {
      setSelectedSources(values);
      if (!isEqual(initialSource, values)) {
        setDisableRetainFilter(true);
      } else {
        setDisableRetainFilter(false);
      }
    },
    [setSelectedSources, initialSource]
  );

  const handleSearch = (value: string = searchText) => {
    handleAutoCompleteClose();
    setSearchHighlighted(false);

    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      search_term: value,
      source: convertedSelectedSources
    };
    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    const decodedPayload: any = decodeBase64ToObject(location.pathname.split('/search/')[1] ?? '');
    if (
      payload &&
      decodedPayload &&
      isEqual(payload.search_term, decodedPayload?.search_term) &&
      isEqual(payload.source, decodedPayload?.source)
    ) {
      return;
    }
    resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });

    // reset the filters when search is triggered with new search term by clicking on suggestion
    if (disableRetainFilter || (!disableRetainFilter && !retainFilter)) {
      if (Object.keys(resultsState?.filters).length > 0) {
        resultsDispatch({ type: ResultActions.SET_FILTERS, value: {} });
        resultsDispatch({ type: ResultActions.SET_IS_NEW_SEARCH_TERM, value: true });
      }
      resultsDispatch({
        type: ResultActions.SET_VIEW_TYPE,
        value: ''
      });
    }
    setInitialSource(deepClone(selectedSources));
    setDisableRetainFilter(false);

    history.push(`/search/${encodedPayload}`);
  };
  const handleStackClick = () => {
    setSearchHighlighted(true);
  };

  useEffect(() => {
    if (location.pathname.includes('search')) {
      const payload = location.pathname.split('/search/')[1];
      const decodedPayload = decodeBase64ToObject(payload);
      const { search_term: searchTerm, search_type: searchType }: any = decodedPayload;
      if (searchType && searchType === 'advanced') {
        setSearchText('');
        setAdvancedSearchActive(true);
      } else {
        setSearchText(searchTerm);
        setAdvancedSearchActive(false);
      }
    }
  }, [location.pathname, searchHighlighted]);
  useEffect(() => {
    if (!selectedSourcesValue || isEmpty(selectedSourcesValue)) {
      setSelectedSources(getDefaultHomePageSourceDropDown());
      setInitialSource(deepClone(getDefaultHomePageSourceDropDown()));
    } else {
      setSelectedSources(selectedSourcesValue);
      setInitialSource(deepClone(selectedSourcesValue));
    }
  }, [selectedSourcesValue]);
  useEffect(() => {
    const mainLogo = document.getElementById('main-vivpro-logo');
    if (!mainLogo) {
      return;
    }

    mainLogo.style.zIndex = searchHighlighted ? '0' : '2';
  }, [searchHighlighted]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectedSources.length === 0) {
        setSearchHighlighted(true);
        // @ts-ignore
      } else if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target)) {
        setSearchHighlighted(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchHighlighted, searchWrapperRef, sourceDropDownOpen, selectedSources.length]);

  if (type === 'nav') {
    return (
      <Box sx={styles.searchContainer}>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSearch();
          }}>
          <Stack direction='row' alignItems='center'>
            <Stack
              ref={searchWrapperRef}
              direction='row'
              alignItems='center'
              id={searchHighlighted ? 'search-bar-highlighted' : 'search-bar'}
              sx={styles.searchHighlighted}
              onKeyDown={handleStackClick}
              onClick={handleStackClick}>
              <SourceDropDown
                selectedSources={selectedSources}
                handleChange={handleSourceChange}
                options={SOURCE_MENU_ITEMS}
                anchorEl={sourceDropDownAnchorEl}
                setAnchorEl={setSourceDropDownAnchorEl}
                type={type}
                showOptions={searchHighlighted}
                setSearchHighlighted={setSearchHighlighted}
              />
              <Box
                flexGrow={1}
                height={40}
                sx={styles.autoComplete}
                id={searchHighlighted ? 'search-box-highlighted' : 'search-box'}>
                <SearchBarAutoComplete
                  id='quick-search-input'
                  disableSearch={selectedSources.length === 0}
                  value={searchText}
                  label='Search for applications or ask RIA a question!'
                  options={searchSuggestions}
                  loading={isSuggestionsLoading}
                  onOpen={handleAutoCompleteOpen}
                  open={isSearchOpen}
                  onClose={handleAutoCompleteClose}
                  filterOptions={filterOptions}
                  onInputChange={handleKeyPress}
                  setSearch={value => setSearchText(value)}
                  clearSearchSuggestions={clearSearchSuggestions}
                  type={type}
                  handleSearch={handleSearch}
                  showRetainFilter={searchHighlighted}
                  retainFilter={retainFilter}
                  onRetainFilterChange={onRetainFilterChange}
                  disableRetainFilter={disableRetainFilter}
                />
              </Box>
            </Stack>

            <AdvancedSearch
              selectedSources={selectedSources}
              type='nav'
              active={advancedSearchActive}
            />
          </Stack>
        </form>
      </Box>
    );
  }
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          ...styles.searchBar,
          borderBottomLeftRadius: sourceDropDownOpen ? 0 : '12px'
        }}>
        <form onSubmit={() => handleSearch()}>
          <Stack direction='row' alignItems='center' justifyContent='end'>
            <SourceDropDown
              selectedSources={selectedSources}
              handleChange={handleSourceChange}
              options={SOURCE_MENU_ITEMS}
              anchorEl={sourceDropDownAnchorEl}
              setAnchorEl={setSourceDropDownAnchorEl}
            />
            <Divider
              orientation='vertical'
              flexItem
              sx={{ backgroundColor: 'gray.200', height: '32px', alignSelf: 'center' }}
            />
            <Box flex={1}>
              <SearchBarAutoComplete
                id='quick-search-input'
                disableSearch={selectedSources.length === 0}
                value={searchText}
                label='Search for products or ask RIA a question!'
                options={searchSuggestions}
                loading={isSuggestionsLoading}
                onOpen={handleAutoCompleteOpen}
                open={isSearchOpen}
                onClose={handleAutoCompleteClose}
                filterOptions={filterOptions}
                onInputChange={handleKeyPress}
                setSearch={value => setSearchText(value)}
                clearSearchSuggestions={clearSearchSuggestions}
                handleSearch={handleSearch}
              />
            </Box>
            <AdvancedSearch selectedSources={selectedSources} type='iconButton' />
            <Button
              type='submit'
              size='small'
              disabled={selectedSources.length === 0}
              startIcon={<SearchIcon style={{ fontSize: 12, color: 'gray.50' }} />}
              sx={styles.searchButton}>
              Search
            </Button>
          </Stack>
        </form>
      </Box>
      <Stack direction='row' justifyContent='space-between'>
        <DocumentPageCount selectedSources={selectedSources} />
        <Box sx={styles.tipsBox}>
          <Link
            variant='body1'
            sx={styles.tipsText}
            href={tipsForBetterSearchLink}
            underline='hover'
            target='_blank'
            rel='noreferrer'>
            Tips for better workflow?
          </Link>
        </Box>
      </Stack>
    </Stack>
  );
};

SearchBar.defaultProps = {
  type: 'home',
  selectedSourcesValue: []
};
export default memo(SearchBar);
